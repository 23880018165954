/* You can add global styles to this file, and also import other style files */

$common-font: 'Poppins', sans-serif;
$heading-font: 'Montserrat', sans-serif;
$transition: .5s all ease;
$all-size: 16px;
$white-color: #ffffff;
$grey-color: #777777;
$black-color: #0d1820;
$gradient-green: linear-gradient(90deg, rgba(16,195,23,1) 23%, rgba(21,208,154,1) 64%);
$main-color: #00C195;
$paragraph-color: #767676;
$main-gradient-color: linear-gradient(270deg, #00C194 0%, #00A47E 100%);

/*-- Default CSS --*/
body {
    font-family: $common-font;
    color: $grey-color;
    background-color: $white-color;
    font-size: $all-size;
}
h1, h2, h3, h4, h5, h6 {
    line-height: 1.3;
    font-family: $heading-font;
    color: $black-color;
}
p {
    line-height: 1.8;
}
a {
    transition: $transition;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}
img {
    max-width: 100%;
}
.d-table {
    width:100%;
    height:100%;
}
.d-table-cell {
    vertical-align: middle;
}
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}
.pt-100 {
    padding-top: 100px;
}
.pb-70 {
    padding-bottom: 70px;
}
.pb-100 {
    padding-bottom: 100px;
}
button {
    &:focus{
        outline: 0;
    }
}
.btn.focus, .btn:focus {
    box-shadow: none;
}
/*-- End Default CSS --*/

/*----- Home One CSS -----*/
/*-- Header Top --*/
.one-header-top {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #f0f0f0;
    padding-right: 40px;
    padding-left: 40px;

    .header-social {
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 10px;

                &:nth-child(2) {
                    a {
                        color: #1da1f2;
                    }
                }
                &:nth-child(3) {
                    a {
                        color: #d43535;
                    }
                }
                &:nth-child(4) {
                    margin-right: 0;

                    a {
                        color: #405de6;
                    }
                }
                a {
                    display: block;
                    color: #0b5998;
                    font-size: 20px;

                    &:hover {
                        transform: rotateY(360deg);
                    }
                }
            }
        }
    }
    .header-partner {
        text-align: center;

        p {
            font-size: 14px;
            color: #606060;
            margin-bottom: 0;

            a {
                display: inline-block;
                color: #232f77;

                &:hover {
                    color: #606060;
                }
            }
        }
    }
    .header-call {
        text-align: right;

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
                span {
                    display: inline-block;
                    color: #606060;
                    font-size: 14px;
                }
                a {
                    display: inline-block;
                    color: #232f77;
                    font-size: 14px;

                    &:hover {
                        color: #606060;
                    }
                }
            }
        }
    }
}
/*-- End Header Top --*/

/*-- Navbar --*/
.navbar-light {
    padding-left: 55px;
    padding-right: 55px;
    background-color: $white-color;
    box-shadow: 0px 20px 20px 0px #dddddd52;

    .navbar-nav {
        .nav-link {
            color: $black-color;
            margin-right: 15px;
            margin-left: 15px;
        }
        .active>.nav-link, .nav-link.active, .nav-link.show, .show>.nav-link {
            color: #12ca59;
        }
        .nav-link:focus, .nav-link:hover {
            color: #12ca59;
        }
        .nav-link:focus, .nav-link:hover {
            color: #12ca59;
        }
    }
    .navbar-nav {
        margin-left: auto;
        margin-right: auto;
    }
    .side-nav {
        a {
            display: inline-block;
            color: $black-color;
        }
        .side-nav-left {
            margin-right: 14px;

            &:hover {
                color: #12ca59;
            }
        }
        .side-nav-right {
            color: $white-color;
            position: relative;
            background-color: $black-color;
            z-index: 1;
            padding: 10px 16px 12px;
            border-radius: 4px;

            &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: $gradient-green;
                z-index: -1;
                border-radius: 4px;
                transition: $transition;
            }
            i {
                display: inline-block;
                font-size: 20px;
                position: relative;
                top: 4px;
                margin-right: 4px;
            }
            &:hover {
                &:before {
                    width: 0;
                }
            }
        }
    }
}
/*-- End Navbar --*/

/*-- Banner --*/
.one-banner-area {
    position: relative;
    z-index: 1;

    .banner-shape {
        img {
            z-index: -1;
            position: absolute;

            &:nth-child(1) {
                top: 0;
                left: 0;
                max-width: 500px;
                animation: banner-ani-one 6s linear infinite;
            }
            &:nth-child(2) {
                left: 300px;
                top: 300px;
                max-width: 500px;
                animation: banner-ani-two 6s linear infinite;
            }
        }
    }
    .container-fluid {
        padding-right: 0;
        padding-left: 0;

        .row {
            margin-left: 0;
            margin-right: 0;

            .col-lg-6 {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }
    .banner-text {
        max-width: 635px;
        margin-left: auto;
        margin-top: 50px;
        padding-right: 50px;

        .banner-video {
            margin-bottom: 20px;

            a {
                text-align: center;
                display: inline-block;
                border-radius: 50%;
                color: $white-color;
                background-color: $black-color;
                font-size: 50px;

                &:hover {
                    background-color: #12ca59;
                }
                i {
                    width: 80px;
                    height: 80px;
                    line-height: 80px;
                }
            }
            span {
                display: inline-block;
                font-weight: 500;
                font-size: 20px;
                color: $black-color;
                position: relative;
                top: -15px;
                margin-left: 14px;
            }
        }
        h1 {
            font-weight: 700;
            margin-bottom: 10px;
            font-size: 95px;
            line-height: 115px;
        }
        p {
            margin-bottom: 50px;
            font-weight: 500;
            color: #1a1b1e;
            font-size: 30px;
        }
        .banner-form {
            .col-lg-6 {
                padding-left: 15px;
                padding-right: 15px;
            }
            .banner-form-span {
                display: inline-block;
                border-bottom: 1px solid #dddddd96;

                span {
                    display: inline-block;
                    font-weight: 500;
                    font-size: 20px;
                    color: $black-color;
                    padding: 15px 22px;
                    box-shadow: 0px 0px 20px 0px #dddddd96;
                }
            }
            form {
                padding: 30px 20px 30px;
                box-shadow: 0px 0px 20px 0px #dddddd96;
                background-color: $white-color;

                .form-group {
                    .form-control {
                        height: 45px;
                        background-color: #f7f7f7;
                        border: 1px solid #eeeeee;
                        border-radius: 0;
                        font-weight: 500;
                        font-size: 15px;
                        width: 100%;
                        &:focus {
                            border: 1px solid #eeeeee;
                            box-shadow: none;
                        }

                        span {
                            font-weight: 500;
                            font-size: 15px;
                            color: #9d9d9d;
                            margin-bottom: 0;
                            line-height: 30px;
                            display: block;
                        }
                        .list {
                            display: block;
                            width: 100%;

                            li {
                                display: block;
                            }
                        }
                    }
                    ::placeholder {
                        color: #9d9d9d;
                    }
                }
                .banner-form-btn {
                    width: 100%;
                    height: 45px;
                    color: $white-color;
                    font-size: 15px;
                    font-weight: 500;
                    transition: $transition;
                    position: relative;
                    z-index: 1;
                    background-color: $black-color;
                    border: 0;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: $gradient-green;
                        z-index: -1;
                        transition: $transition;
                    }
                    &:hover {
                        &:before {
                            width: 0;
                        }
                    }
                }
                .banner-call {
                    width: 100%;
                    height: 45px;
                    color: $white-color;
                    font-size: 15px;
                    font-weight: 500;
                    background-color: $black-color;
                    display: block;
                    text-align: center;
                    line-height: 45px;

                    &:hover {
                        background-color: #12ca59;
                    }
                }
            }
        }
    }
    .banner-slider {
        .banner-img-one {
            background-image: url('assets/home/paddy_3-1.jpg');
        }
        .banner-img-two {
            background-image: url('assets/home/paddy1-1.jpg');
        }
        .banner-img-three {
            background-image: url('assets/home/paddy21-1.jpg');
        }
        .banner-inner {
            height: 900px;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;

            img {
                position: absolute;
                left: 0;
                top: 0;
                max-width: 275px;
                height: 100%;            
            }
        }
        .owl-prev {
            width: 95px;
            height: 90px;
            font-size: 50px !important;
            line-height: 100px !important;
            border-radius: 4px !important;
            box-shadow: 0px 0px 20px 0px #dddddd52;
            background-color: #efefef !important;
            transition: $transition;

            &:hover {
                color: #12ca59 !important;
                background-color: $white-color !important;
            }
        }
        .owl-next {
            width: 95px;
            height: 90px;
            font-size: 50px !important;
            line-height: 100px !important;
            border-radius: 4px !important;
            box-shadow: 0px 0px 20px 0px #dddddd52;
            background-color: #efefef !important;
            transition: $transition;

            &:hover {
                color: #12ca59 !important;
                background-color: $white-color !important;
            }
        }
    }
    .owl-theme .owl-nav {
        margin-top: 0;
        position: absolute;
        bottom: -45px;
        right: 0;
    }
    @keyframes banner-ani-one {
        0% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(0, 50px);
        }
        100% {
            transform: translate(0, 0px);
        }
    }
    @keyframes banner-ani-two {
        0% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(0, -50px);
        }
        100% {
            transform: translate(0, 0px);
        }
    }
}
/*-- End Banner --*/

/*-- About --*/
.one-section-title {
    text-align: center;
    margin-bottom: 50px;

    .sub-title {
        display: inline-block;
        font-weight: 600;
        font-size: 18px;
        background: $gradient-green;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;
        margin-bottom: 5px;

        &:before {
            position: absolute;
            content: '';
            width: 40px;
            height: 3px;
            right: -48px;
            bottom: 7px;
            background: $gradient-green;
        }
    }
    h2 {
        font-weight: 700;
        font-size: 60px;
        margin-bottom: 0;
    }
    p {
        margin-top: 5px;
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
    }
}
.one-about-area {
    padding-top: 95px;

    .col-lg-6 {
        padding-left: 0;
        padding-right: 0;
    }
    .one-section-title {
        text-align: left;
        margin-bottom: 30px;
    }
    .about-img {
        background-image: url('assets/home/about-1.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        
        img {
            display: none;
        }
    }
    .about-content {
        max-width: 545px;
        margin-left: 70px;
        padding-top: 100px;
        padding-bottom: 100px;

        .about-tablist {
            background: $gradient-green;
            padding: 30px 30px 30px;
            margin-bottom: 20px;

            ul {
                margin-bottom: 25px;

                li {
                    margin-right: 8px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        color: $white-color;
                        background-color: #3ae283;
                        font-weight: 500;
                        text-transform: uppercase;
                        padding: 10px 18px 10px;
                        border-radius: 4px;

                        &.active {
                            background: $white-color;
                            color: #121682;
                        }
                        i {
                            display: inline-block;
                            position: relative;
                            top: 4px;
                            font-size: 20px;
                            margin-right: 3px;
                        }
                    }
                }
            }
            p {
                margin-bottom: 0;
                color: $white-color;
                font-size: 15px;
            }
        }
        .about-best {
            ul {
                display: flex;
                flex-wrap: wrap;
                margin: 0;
                padding: 0;

                li {
                    flex: 0 0 33%;
                    max-width: 33%;
                    list-style-type: none;
                    display: inline-block;
                    background-color: #f4f4f4;
                    padding: 15px 20px 15px;
                    margin-right: 15px;

                    &:last-child {
                        flex: 0 0 64%;
                        max-width: 64%;
                        margin-right: 0;
                    }
                    h4 {
                        font-family: $common-font;
                        font-weight: 500;
                        font-size: 18px;
                        color: $black-color;
                        margin-bottom: 0;
                        line-height: 28px;
                    }
                    img {
                        padding-right: 25px;
                        border-right: 1px solid #bcbcbc;
                        margin-right: 25px;
                        margin-top: -26px;
                    }
                    .sig-inner {
                        display: inline-block;

                        span {
                            display: block;
                            font-weight: 500;
                            color: #10c317;
                            margin-bottom: 3px;
                        }
                        p {
                            margin-bottom: 0;
                            font-weight: 500;
                            font-size: 14px;
                            color: #909090;
                        }
                    }
                }
            }
        }
    }
}
/*-- End About --*/

/*-- Counter --*/
.one-counter-area {
    background-color: $black-color;
    
    .counter-item {
        position: relative;
        margin-bottom: 30px;

        h3 {
            font-weight: 700;
            font-size: 75px;
            color: $white-color;
            margin-bottom: 0;
            position: absolute;
            top: 0;
            left: 0;
        }
        p {
            margin-bottom: 0;
            font-size: 18px;
            color: $white-color;
            padding-left: 110px;
            padding-top: 20px;
        }
    }
}
/*-- End Counter --*/

/*-- Service --*/
.one-service-area {
    nav {
        .nav-tabs {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 0;

            .nav-default {
                display: inline-block;
                margin-bottom: 30px;
                flex: 0 0 16.6666666667%;
                max-width: 16.6666666667%;
                padding-left: 15px;
                padding-right: 15px;

                &.active {
                    .service-item {
                        &:before {
                            opacity: 1;
                        }
                        i {
                            color: $white-color;
                        }
                        span {
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
    .service-item {
        text-align: center;
        background-color: #efefef;
        border-radius: 4px;
        padding: 20px 25px 18px;
        position: relative;
        z-index: 1;

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: $gradient-green;
            border-radius: 4px;
            opacity: 0;
            z-index: -1;
        }
        i {
            display: block;
            color: $black-color;
            font-size: 50px;
            margin-bottom: 15px;
            transition: $transition;
        }
        span {
            display: block;
            color: $black-color;
            font-weight: 500;
            font-size: $all-size;
            transition: $transition;
        }
    }
    .service-advisor {
        padding: 35px 35px 35px;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px #dddddd96;

        .advisor-content {
            h2 {
                font-size: 28px;
                font-weight: 700;
                margin-bottom: 16px;
            }
            p {
                margin-bottom: 20px;
            }
            ul {
                margin: 0;
                padding: 0;
                margin-bottom: 22px;

                li {
                    list-style-type: none;
                    display: block;
                    color: #444444;
                    margin-bottom: 12px;
                    font-size: $all-size;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    i {
                        display: inline-block;
                        font-size: 20px;
                        position: relative;
                        top: 3px;
                        margin-right: 3px;
                        background: $gradient-green;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
            .advisor-link {
                display: inline-block;

                &:hover {
                    i {
                        left: -3px;
                    }
                }
                a {
                    display: inline-block;
                    font-weight: 600;
                    font-size: $all-size;
                    background: $gradient-green;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                i {
                    display: inline-block;
                    color: #12ca59;
                    font-size: 21px;
                    position: relative;
                    top: 4px;
                    left: 0;
                    transition: $transition;
                }
            }
        }
    }
}
/*-- End Service --*/

/*-- Help --*/
.one-help-area {
    background-color: $black-color;
    position: relative;

    .help-img {
        position: absolute;
        top: 180px;
        right: 0;
        max-width: 435px;
    }
    .help-item {
        max-width: 790px;

        .one-section-title {
            text-align: left;

            h2 {
                color: $white-color;
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;    
                flex: 0 0 50%;
                max-width: 50%;
                position: relative;
                margin-bottom: 40px;

                &:hover {
                    .help-icon {
                        background-color: $white-color;
                    }
                }
                .help-icon {
                    display: inline-block;
                    background-color: #233644;
                    border-radius: 4px;
                    text-align: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: $transition;

                    i {
                        font-size: 38px;
                        width: 80px;
                        height: 65px;
                        line-height: 65px;
                        background: $gradient-green;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        box-shadow: 0px 10px 25px 0px #091117;
                    }
                }
                .help-inner {
                    padding-left: 110px;

                    h3 {
                        font-weight: 500;
                        font-size: 20px;
                        color: $white-color;
                        margin-bottom: 10px;
                    }
                    p {
                        margin-bottom: 0;
                        font-size: 15px;
                        color: #b2bac0;
                    }
                }
            }
        }
        .advisor-link {
            display: inline-block;

            &:hover {
                i {
                    left: -3px;
                }
            }
            a {
                display: inline-block;
                font-weight: 600;
                font-size: $all-size;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            i {
                display: inline-block;
                color: #12ca59;
                font-size: 21px;
                position: relative;
                top: 4px;
                left: 0;
                transition: $transition;
            }
        }
    }
}
/*-- End Help --*/

/*-- Among --*/
.one-among-area {
    .among-item {
        border-radius: 4px;
        box-shadow: 0px 0px 17px 0px #dddddd59;
        transition: $transition;
        margin: 15px;

        &:hover {
            transform: translate(0, -10px);

            .among-top {
                .among-icon {
                    left: 40%;
                }
            }
            .among-bottom {
                border-bottom: 3px solid;
                border-image-source: $gradient-green;
                border-image-slice: 1;
            }
        }
        .among-top {
            position: relative;

            img {
                width: 100%;
            }
            .among-icon {
                display: inline-block;
                background-color: $white-color;
                position: absolute;
                left: 30px;
                bottom: -15px;
                border-radius: 50%;
                box-shadow: 0px 0px 20px 0px #ddddddd4;
                width: 70px;
                height: 70px;
                transition: $transition;

                i {
                    width: 70px;
                    height: 70px;
                    line-height: 70px;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 50px;
                    background: $gradient-green;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    transition: $transition;
                }
            }
        }
        .among-bottom {
            padding: 38px 30px 30px;
            border-bottom: 3px solid transparent;
            transition: $transition;

            h3 {
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 15px;
                font-size: 15px;
            }
            a {
                display: inline-block;
                font-weight: 600;
                font-size: 14px;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                &:hover {
                    i {
                        left: -3px;
                    }
                }
            }
            i {
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                display: inline-block;
                font-size: 20px;
                position: relative;
                top: 4px;
                left: 0;
                transition: $transition;
            }
        }
    }
    .owl-theme .owl-dots .owl-dot span {
        width: 16px;
        height: 5px;
        background: #e5e5e5;
        margin: 5px 5px;
    }
    .owl-theme .owl-dots .owl-dot.active span {
        background: #12ca59;
        width: 21px;
    }
    .center {
        transform: translate(0, -10px);

        .among-top {
            .among-icon {
                left: 40%;
            }
        }
        .among-bottom {
            border-bottom: 3px solid;
            border-image-source: $gradient-green;
            border-image-slice: 1;
        }
    }
}
/*-- End Among --*/

/*-- Quote --*/
.one-quote-area {
    padding-bottom: 110px;

    .row {
        box-shadow: 0px 0px 20px 0px #dddddd91;
        border-radius: 4px;
    }
    .quote-content {
        padding: 28px 35px 35px;

        h3 {
            font-weight: 700;
            font-size: 28px;
            margin-bottom: 12px;
        }
        p {
            font-size: 15px;
            margin-bottom: 18px;
        }
        a {
            color: $white-color;
            position: relative;
            background-color: $black-color;
            z-index: 1;
            padding: 10px 80px 12px;
            border-radius: 4px;
            font-size: 17px;
            display: inline-block;

            &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: $gradient-green;
                z-index: -1;
                border-radius: 4px;
                transition: $transition;
            }
            i {
                display: inline-block;
                font-size: 22px;
                position: relative;
                top: 3px;
                margin-right: 4px;
            }
            &:hover {
                &:before {
                    width: 0;
                }
            }
        }
    }
    .quote-img {
        // background-image: url('assets/img/home-one/quote.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;

        img {
            display: none;
        }
    }
}
/*-- End Quote --*/

/*-- Search --*/
.one-search-area {
    // background-image: url('assets/img/home-one/search-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0.386992) 80%);
    }
    .search-wrap {
        position: relative;

        .search-img {
            position: absolute;
            bottom: -100px;
            right: 0;
            max-width: 515px;
        }
    }
    .search-content {
        position: relative;
        max-width: 640px;

        .one-section-title {
            text-align: left;
            margin-bottom: 40px;

            h2 {
                color: $white-color;
            }
        }
        .form-check {
            display: inline-block;
            margin-right: 15px;

            label {
                font-size: 17px;
                color: $white-color;
                margin-left: 5px;
            }
            .form-check-input {
                margin-top: 5px;
                width: 17px;
                height: 17px;
                top: 0px;

                &:focus {
                    box-shadow: none;
                    border: 0;
                }
            }
        }
        input[type='radio']:after {
            width: 11px;
            height: 11px;
            border-radius: 15px;
            top: -3px;
            left: 3px;
            position: relative;
            background-color: #fff;
            content: '';
            display: inline-block;
        }
        input[type='radio']:checked:after {
            background-color: #12ca59;
        }
        .row {
            margin-top: 23px;

            .form-group {
                margin-bottom: 0px;

                .form-control {
                    border-radius: 4px;
                    height: 50px;
                    border: 0;
                    padding-left: 20px;
                    font-weight: 500;
                    font-size: 15px;
                    width: 100%;
                    color: #797979;
                    display: block;

                    &:focus {
                        box-shadow: none;
                        border: 0;
                    }
                }
                ::placeholder {
                    color: #797979;
                }
                .list {
                    width: 100%;
                    display: block;
                }
            }
        }
        .search-link {
            margin-top: 25px;

            button {
                color: $white-color;
                position: relative;
                background-color: $black-color;
                z-index: 1;
                padding: 16px 75px 16px;
                border-radius: 4px;
                font-size: 17px;
                display: inline-block;
                border: none;
                box-shadow: unset !important;
    
                &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: $gradient-green;
                    z-index: -1;
                    border-radius: 4px;
                    transition: $transition;
                }
                i {
                    display: inline-block;
                    font-size: 22px;
                    position: relative;
                    top: 3px;
                    margin-right: 4px;
                }
                &:hover {
                    &:before {
                        width: 0;
                    }
                }
            }
        }
    }
}
/*-- End Search --*/

/*-- Team --*/
.one-team-area {
    .one-section-title {
        text-align: left;
        position: relative;

        a {
            display: inline-block;
            color: $white-color;
            background-color: $black-color;
            border-radius: 4px;
            padding: 14px 18px 14px;
            font-size: 17px;
            position: absolute;
            bottom: 0;
            right: 0;

            &:hover {
                background-color: #12ca59;
            }
            i {
                display: inline-block;
                font-size: 22px;
                position: relative;
                top: 3px;
            }
        }
    }
    .team-item {
        text-align: center;
        margin-bottom: 30px;

        &:hover {
            ul {
                li {
                    &:nth-child(1) {
                        top: -20px;
                        opacity: 1;
                    }
                    &:nth-child(2) {
                        top: -20px;
                        opacity: 1;
                    }
                    &:nth-child(3) {
                        top: -20px;
                        opacity: 1;
                    }
                    &:nth-child(4) {
                        top: -20px;
                        opacity: 1;
                    }
                }
            }
        }
        .team-img {
            position: relative;

            &:before {
                position: absolute;
                content: '';
                width: 263px;
                height: 263px;
                border-radius: 50%;
                left: -4px;
                right: 0;
                top: -4px;
                border: 2px dashed #12ca59;
            }
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 4px;
                margin-left: 4px;
                position: relative;

                &:nth-child(1) {
                    transition: .3s;
                    opacity: 0;
                    top: 0;
                }
                &:nth-child(2) {
                    transition: .5s;
                    opacity: 0;
                    top: 0;
                }
                &:nth-child(3) {
                    transition: .7s;
                    opacity: 0;
                    top: 0;
                }
                &:nth-child(4) {
                    transition: .9s;
                    opacity: 0;
                    top: 0;
                }
                a {
                    display: block;
                    background-color: $white-color;
                    text-align: center;
                    box-shadow: 0px 0px 20px 0px #ddd;
                    border-radius: 50%;
                    
                    &:hover {
                        transform: rotateY(360deg);
                    }
                    i {
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        background: $gradient-green;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
        h3 {
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 8px;
            text-transform: uppercase;
            color: #08104d;
        }
        span {
            display: block;
            font-size: 15px;
            color: #64677c;
        }
    }
}
/*-- End Team --*/

/*-- FAQ --*/
.one-faq-area {
    background-image: url('assets/img/home-one/faq-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    .faq-img {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 560px;
    }
    .faq-content {
        max-width: 650px;

        .accordion { 
            padding-left: 0;
            margin-bottom: 0;
        
            a {
                width: 100%;
                font-weight: 600;
                display: block;
                cursor: pointer;
                font-size: 20px;
                color: #1a1b1e;
                padding: 20px 35px 20px;
        
                &:after {
                    position: absolute;
                    content: '+';
                    width: 35px;
                    height: 35px;
                    line-height: 36px;
                    right: 35px;
                    top: 17px;
                    color: $white-color;
                    text-align: center;
                    background: $gradient-green;
                    border-radius: 50%;
                    font-size: 26px;
                }
                &.active {
                    background-color: $white-color;

                    &:after {
                        content: '-';
                    }
                }
            }
            p {
                display: none;
                margin-bottom: 0;
                color: #848484;
                font-size: 14px;
                padding: 0px 185px 25px 35px;
                background-color: $white-color;
            }
            li {
                position: relative;
                list-style-type: none;
                margin-bottom: 15px;
                background-color: #f5f5f5;
                border: 1px solid #d9d9d9;
        
                &:last-child {
                    margin-bottom: 0;
                }
                &.active {
                    background-color: $white-color;
                }
            }
        }
    }
}
/*-- End FAQ --*/

/*-- Testimonial --*/
.one-testimonial-area {
    .testimonial-item {
        text-align: center;
        background-color: $white-color;
        box-shadow: 0px 0px 20px 0px #dddddd85;
        padding: 30px 35px 30px;
        border-radius: 4px;
        margin-bottom: 30px;
        transition: $transition;
    
        &:hover, &.active {
            background-color: $black-color;
            transform: translate(0, -10px);

            .testimonial-top {
                ul {
                    &:before {
                        background-color: #172631;
                    }
                    &:after {
                        background-color: #172631;
                    }
                }
                .test-img {
                    img {
                        border: 8px solid #314c60;
                        box-shadow: none;
                    }
                }
                h3 {
                    color: $white-color;
                }
                span {
                    border-bottom: 1px solid #172631;
                }
            }
            .testimonial-bottom {
                span {
                    color: $white-color;
                }
                p {
                    color: #b2bac0;
                }
            }
        }
        .testimonial-top {
            margin-bottom: 15px;

            .test-img {
                position: relative;
                display: inline-block;

                img {
                    box-shadow: 0px 0px 20px 0px #dddddd85;
                    border: 8px solid $white-color;
                    border-radius: 50%;
                    transition: $transition;
                }
                i {
                    width: 45px;
                    height: 45px;
                    line-height: 45px;
                    border-radius: 50%;
                    text-align: center;
                    color: $white-color;
                    background: $gradient-green;
                    font-size: 20px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
            ul {
                margin: 0;
                padding: 0;
                margin-top: 30px;
                position: relative;
                margin-bottom: 20px;

                &:before {
                    position: absolute;
                    content: '';
                    left: -35px;
                    top: 10px;
                    width: 34%;
                    height: 2px;
                    background-color: #ecf3f7;
                    transition: $transition;
                }
                &:after {
                    position: absolute;
                    content: '';
                    right: -35px;
                    top: 10px;
                    width: 34%;
                    height: 2px;
                    background-color: #ecf3f7;
                    transition: $transition;
                }
                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 3px;
                    margin-left: 3px;

                    i {
                        color: #ddd;
                        font-size: 20px;
                    }
                    .checked {
                        color: #ff9c00;
                    }
                }
            }
            h3 {
                font-weight: 500;
                font-size: 20px;
                margin-bottom: 8px;
                transition: $transition;
            }
            span {
                display: block;
                font-size: 15px;
                color: #11c744;
                padding-bottom: 18px;
                border-bottom: 1px solid #dce8f0;
                transition: $transition;
            }
        }
        .testimonial-bottom {
            span {
                color: #3b474f;
                font-weight: 500;
                font-size: 17px;
                margin-bottom: 6px;
                display: block;
                transition: $transition;
            }
            p {
                color: #555555;
                font-size: 15px;
                margin-bottom: 0;
                transition: $transition;
            }
        }
    }
}
/*-- End Testimonial --*/

/*-- Contact --*/
.one-contact-area {
    background-color: green;

    .contact-img {
        background-image: url('assets/home/contact-1-800.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;

        img {
            display: none;
        }
    }
    .contact-content {
        padding: 100px 0 100px;
        max-width: 635px;
        margin-left: 50px;

        .one-section-title {
            text-align: left;
            margin-bottom: 30px;
            margin-left: 15px;

            h2 {
                color: $white-color;
            }
        }
        .form-group {
            margin-bottom: 20px;
            position: relative;

            label {
                margin: 0;
                color: #7f7f7f;
                display: inline-block;
                font-size: 18px;
                position: absolute;
                top: 12px;
                left: 25px;
            }
            .form-control {
                height: 50px;
                border-radius: 0;
                background-color: $white-color;
                padding-left: 50px;
                border: 0;
                font-weight: 500;
                font-size: 15px;

                &:focus {
                    box-shadow: none;
                    border: 0;
                }
            }
            textarea {
                height: auto !important;
                padding-top: 12px;
            }
            ::placeholder {
                color: #7f7f7f;
            }
        }
        .contact-btn {
            color: $white-color;
            position: relative;
            background-color: $white-color;
            z-index: 1;
            padding: 11px 18px 12px 14px;
            border-radius: 4px;
            font-size: 16px;
            display: inline-block;
            opacity: 1;
            text-transform: uppercase;
            transition: $transition;
            border: 0;
            margin-top: 20px;

            &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: $gradient-green;
                z-index: -1;
                border-radius: 4px;
                transition: $transition;
            }
            i {
                display: inline-block;
                font-size: 22px;
                position: relative;
                top: 3px;
                margin-right: 4px;
            }
            &:hover {
                color: $black-color;
                
                &:before {
                    width: 0;
                }
            }
        }
        .contact-emergency {
            position: relative;
            padding-left: 75px;
            margin-top: 15px;

            i {
                display: inline-block;
                color: $white-color;
                position: absolute;
                top: 0;
                left: 0;
                font-size: 60px;
            }
            span {
                display: block;
                font-size: $all-size;
                color: $white-color;
                margin-bottom: 2px;
            }
            a {
                display: block;
                font-weight: 500;
                font-size: 22px;
                color: $white-color;

                &:hover {
                    color: #12ca59;
                }
            }
        }
        .list-unstyled {
            color: #dc3545;
            font-size: 14px;
            margin-top: 10px;
        }
        .text-danger {
            color: #dc3545;
            margin-top: 10px;
            font-size: 20px;
        }
        .text-success {
            color: #28a745;
            margin-top: 10px;
            font-size: 20px;
        }
    }
}
/*-- End Contact --*/

/*-- Blog --*/
.one-blog-area {
    .one-section-title {
        h2 {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .blog-item {
        margin-bottom: 30px;
        transition: $transition;
        box-shadow: 0px 0px 20px 0px #ddddddab;
        background-color: $white-color;

        &:hover {
            transform: translate(0, -10px);
        }
        .blog-top {
            position: relative;

            a {
                display: block;

                img {
                    width: 100%;
                }
            }
            span {
                display: inline-block;
                color: $white-color;
                font-size: 13px;
                background: $gradient-green;
                padding: 7px 12px;
                position: absolute;
                bottom: 0;
                right: 35px;
            }
        }
        .blog-bottom {
            padding: 20px 25px 20px;

            ul {
                margin: 0;
                padding: 0;
                margin-bottom: 18px;

                li {
                    list-style-type: none;
                    display: inline-block;
                    color: #767676;
                    font-size: 13px;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        display: inline-block;
                        color: #395dc7;
                        font-size: 14px;
                        position: relative;
                        top: 1px;
                        margin-right: 1px;
                    }
                }
            }
            h3 {
                font-weight: 600;
                font-size: 20px;
                padding-bottom: 15px;
                border-bottom: 1px solid #e6e6e6;
                margin-bottom: 15px;

                a {
                    color: $black-color;
                    display: inline-block;

                    &:hover {
                        color: #12ca59;
                    }
                }
            }
            img {
                display: inline-block;
                margin-right: 3px;
            }
            p {
                margin-bottom: 0;
                display: inline-block;
                font-size: 13px;
                color: #525252;

                a {
                    display: inline-block;
                    color: #525252;

                    &:hover {
                        color: #12ca59;
                    }
                }
            }
        }
    }
}
/*-- End Blog --*/

/*-- Subscribe --*/
.one-subscribe-area {
    .subscribe-wrap {
        // background-image: url('assets/img/home-one/subscribe-bg.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        text-align: center;
        position: relative;
        padding: 100px 100px 100px;

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(90deg, rgba(13,24,32,0.8900025614191976) 43%, rgba(13,24,32,0.28887896591357975) 95%);
        }
        h2 {
            color: $white-color;
            font-size: 35px;
            font-weight: 600;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
            position: relative;
        }
        .newsletter-form {
            max-width: 550px;
            margin: 0 auto;
            position: relative;
    
            .form-control {
                height: 55px;
                border: 0;
                border-radius: 0;
                padding-left: 25px;
                font-size: 14px;
                border-radius: 4px;
    
                &:focus {
                    box-shadow: none;
                    border: 0;
                }
            }
            ::placeholder {
                color: #888888;
            }
            .subscribe-btn {
                color: $white-color;
                position: relative;
                background-color: $black-color;
                z-index: 1;
                padding: 18px 20px 16px;
                border-radius: 4px;
                font-size: 15px;
                display: inline-block;
                font-weight: 500;
                opacity: 1;
                text-transform: uppercase;
                transition: $transition;
                border-radius: 4px;
                position: absolute;
                top: 0;
                right: 0;
                border: 0;
    
                &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: $gradient-green;
                    z-index: -1;
                    border-radius: 4px;
                    transition: $transition;
                }
                i {
                    display: inline-block;
                    font-size: 22px;
                    position: relative;
                    top: 3px;
                    margin-right: 4px;
                }
                &:hover {
                    &:before {
                        width: 0;
                    }
                }
            }
            .validation-danger {
                color: $white-color;
                font-size: 14px;
                margin-top: 10px;
            }
        }
    }
}
/*-- End Subscribe --*/

/*-- Footer --*/
.one-footer-area {
    background-color: $black-color;

    .footer-item {
        margin-bottom: 30px;

        .footer-logo {
            a {
                display: block;
                margin-bottom: 35px;
            }
            p {
                font-size: $all-size;
                color: #bdcbd5;
                margin-bottom: 14px;
            }
            ul {
                margin: 0;
                padding: 0;
                padding-top: 20px;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 5px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: block;
                        border: 1px solid #8f8f8f;
                        text-align: center;
                        border-radius: 50%;
                        color: #8f8f8f;
                        font-size: 18px;
                        margin-bottom: 0;

                        &:hover {
                            color: $white-color;
                            background-color: #8f8f8f;
                        }
                        i {
                            width: 35px;
                            height: 35px;
                            line-height: 35px;
                        }
                    }
                }
            }
        }
        .footer-quick {
            h3 {
                margin-bottom: 25px;
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 25px;
                color: $white-color;
            }
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    margin-bottom: 11px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        display: block;
                        font-size: $all-size;
                        color: #bdcbd5;

                        &:hover {
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
        .footer-address {
            h3 {
                margin-bottom: 25px;
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 25px;
                color: $white-color;
            }
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: block;
                    font-size: $all-size;
                    color: #bdcbd5;
                    margin-bottom: 15px;
                    position: relative;
                    padding-left: 33px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    i {
                        position: absolute;
                        top: 2px;
                        left: 0;
                        font-size: 20px;
                        background: $gradient-green;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                    a {
                        color: #bdcbd5;

                        &:hover {
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
    }
    .copyright-area {
        margin-top: 70px;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid #212e37;

        .copyright-item {
            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 8px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: block;

                        &:hover {
                            transform: translate(0, -5px);
                        }
                    }
                }
            }
            p {
                margin-bottom: 0;
                font-size: $all-size;
                color: #767676;
                text-align: right;

                a {
                    display: inline-block;
                    color: $white-color;
                    border-bottom: 1px solid $white-color;

                    &:hover {
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
}
/*-- End Footer --*/
/*----- End Home One CSS -----*/

/*----- Home Two CSS -----*/
/*-- Banner --*/
.two-banner-one {
    background-image: url('assets/img/home-two/banner-slider1.jpg');
}
.two-banner-two {
    background-image: url('assets/img/home-two/banner-slider2.jpg');
}
.two-banner-three {
    background-image: url('assets/img/home-two/banner-slider3.jpg');
}
.two-banner-area {
    height: 850px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    text-align: center;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .50;
    }
    .banner-content {
        position: relative;
        margin-top: -135px;
        max-width: 955px;
        margin-left: auto;
        margin-right: auto;

        span {
            display: block;
            font-weight: 500;
            font-size: 25px;
            color: $white-color;
            margin-bottom: 25px;

            img {
                max-width: 50px;
                display: inline-block;
                margin-right: 5px;
            }
        }
        h1 {
            font-weight: 700;
            font-size: 100px;
            color: $white-color;
            line-height: 110px;
            margin-bottom: 45px;
        }
        .banner-btn {
            a {
                display: inline-block;
                color: $white-color;

                i {
                    display: inline-block;
                    font-size: 20px;
                    position: relative;
                    top: 5px;
                    margin-right: 4px;
                }
            }
            .cmn-banner-btn {
                margin-right: 10px;
            }
            .banner-right-btn {
                border: 1px solid $white-color;
                border-radius: 4px;
                padding: 11px 25px 13px;
                margin-left: 10px;

                i {
                    top: 4px;
                }
                &:hover {
                    color: $white-color;
                    background-color: $black-color;
                    border: 1px solid $black-color;
                }
            }
        }
    }
}
.cmn-banner-btn {
    color: $white-color;
    position: relative;
    background-color: $black-color;
    z-index: 1;
    padding: 12px 25px 14px;
    border-radius: 4px;
    font-size: 16px;
    display: inline-block;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: $gradient-green;
        z-index: -1;
        border-radius: 4px;
        transition: $transition;
    }
    i {
        display: inline-block;
        font-size: 20px;
        position: relative;
        top: 5px;
        margin-right: 4px;
    }
    &:hover {
        color: $white-color;

        &:before {
            width: 0;
        }
    }
}
.two-banner-wrap {
    position: relative;

    .container-fluid {
        padding-left: 70px;
        padding-right: 70px;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;

        .banner-time-left {
            padding: 50px 45px 50px;
            position: relative;

            &:before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #000;
                opacity: .50;
            }
            ul {
                margin: 0;
                padding: 0;
                position: relative;

                li {
                    list-style-type: none;
                    display: inline-block;
                    position: relative;
                    padding-left: 70px;
                    margin-right: 22px;

                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        position: absolute;
                        top: 0px;
                        left: 0;
                        color: #888888;
                        font-size: 55px;
                    }
                    span {
                        display: block;
                        font-weight: 500;
                        font-size: 16px;
                        color: #d9d9d9;
                        margin-bottom: 5px;
                    }
                    p {
                        margin-bottom: 0;
                        font-weight: 500;
                        font-size: 18px;
                        color: $white-color;
                    }
                    a {
                        display: inline-block;
                        font-weight: 500;
                        font-size: 18px;
                        color: $white-color;

                        &:hover {
                            color: #12ca59;
                        }
                    }
                }
            }
        }
        .banner-time-right {
            background-color: $white-color;
            padding: 50px 35px 50px 45px;
            box-shadow: 0px 10px 20px -10px #dddddd9c;

            ul {
                margin: 0;
                padding: 0;

                li {
                    list-style-type: none;
                    display: inline-block;
                    position: relative;
                    padding-left: 70px;
                    margin-right: 22px;

                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        position: absolute;
                        top: 3px;
                        left: 0;
                        background: linear-gradient(90deg, #10c317 23%, #15d09a 64%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-size: 55px;
                    }
                    span {
                        display: block;
                        font-weight: 500;
                        font-size: 16px;
                        color: #5d5d5d;
                        margin-bottom: 5px;
                    }
                    p {
                        margin-bottom: 0;
                        font-weight: 500;
                        font-size: 18px;
                        color: #3c3b3b;
                    }
                    a {
                        display: inline-block;
                        font-weight: 500;
                        font-size: 18px;
                        color: #414141;

                        &:hover {
                            color: #12ca59;
                        }
                    }
                }
            }
        }
    }
    .two-banner-slider {
        &:hover {
            .owl-prev {
                opacity: 1;
                left: 40px;
            }
            .owl-next {
                opacity: 1;
                right: 40px;
            }
        }
        .owl-prev {
            position: absolute;
            top: 45%;
            left: 30px;
            font-size: 50px !important;
            color: #fff !important;
            opacity: 0;
            transition: $transition;

            &:hover {
                color: #12ca59 !important;
                background-color: transparent !important;
            }
        }
        .owl-next {
            position: absolute;
            top: 45%;
            right: 30px;
            font-size: 50px !important;
            color: #fff !important;
            opacity: 0;
            transition: $transition;

            &:hover {
                color: #12ca59 !important;
                background-color: transparent !important;
            }
        }
    }
    .owl-theme .owl-nav {
        margin-top: 0;
    }
}
/*-- End Banner --*/

/*-- Mission --*/
.two-mission-area {
    .one-section-title {
        h2 {
            max-width: 825px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .mission-content {
        position: relative;
        margin-bottom: -35px;

        img {
            border-radius: 5px;
        }
        .mission-bottom {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            padding-right: 50px;
            padding-left: 50px;

            ul {
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;

                li {
                    list-style-type: none;
                    display: inline-block;
                    border-right: 1px dashed #000000;
                    background-color: $white-color;
                    border-radius: 4px;

                    &:nth-child(1) {
                        flex: 0 0 40%;
                        max-width: 40%;
                        background-color: unset;
                        border-right: 0;
                    }
                    &:nth-child(2) {
                        flex: 0 0 15%;
                        max-width: 15%;
                    }
                    &:nth-child(3) {
                        flex: 0 0 15%;
                        max-width: 15%;
                    }
                    &:nth-child(4) {
                        flex: 0 0 15%;
                        max-width: 15%;
                    }
                    &:nth-child(5) {
                        flex: 0 0 15%;
                        max-width: 15%;
                        border-right: 0;
                    }
                    a {
                        display: inline-block;
                        border-radius: 50%;
                        color: $white-color;
                        background-color: $black-color;
                        text-align: center;
                        font-size: 45px;
                        margin-right: 15px;

                        i {
                            width: 75px;
                            height: 75px;
                            line-height: 75px;
                        }
                        &:hover {
                            background-color: #12ca59;
                        }
                    }
                    p {
                        display: inline-block;
                        font-weight: 500;
                        font-size: 20px;
                        color: $white-color;
                        margin-bottom: 0;
                        position: relative;
                        top: -13px;
                    }
                    .mission-item {
                        background-color: $white-color;
                        text-align: center;
                        padding: 20px 20px 18px;

                        i {
                            display: block;
                            background: $gradient-green;
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            font-size: 60px;
                            margin-bottom: 14px;
                        }
                        span {
                            display: block;
                            font-weight: 500;
                            font-size: $all-size;
                            color: $black-color;
                        }
                    }
                }
            }
        }
    }
}
/*-- End Mission --*/

/*-- Counter --*/
.two-counter-area {
    padding-top: 135px;
}
/*-- End Counter --*/

/*-- Among --*/
.two-among-area {
    .among-item {
        margin: 0;
        margin-bottom: 30px;
        background-color: #edfffb;

        &:hover {
            background-color: $white-color;

            .among-top {
                .among-icon {
                    transform: rotateY(360deg);
                }
            }
            .among-bottom {
                border-bottom: none;
            }
        }
        .among-top {
            .among-icon {
                left: 40%;
            }
        }
        .among-bottom {
            border-bottom: none;
            padding: 38px 20px 30px;

            h3 {
                font-size: 19px;
            }
        }
    }
}
/*-- End Among --*/

/*-- House --*/
.two-house-area {
    background-image: url('assets/img/home-two/house-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $black-color;
        opacity: .80;
    }
    .house-item {
        margin-bottom: 30px;
        text-align: center;
        position: relative;
        transition: $transition;

        &:hover {
            transform: translate(0, -10px);

            .house-icon {
                &:before {
                    transform: rotate(200deg);
                }
                &:after {
                    transform: rotate(-200deg);
                }
            }
            a {
                color: #12ca59;

                i {
                    left: -1px;
                    color: #12ca59;
                }
            }
        }
        .house-icon {
            display: inline-block;
            position: relative;
            text-align: center;
            width: 80px;
            height: 80px;
            margin-top: 15px;
            margin-bottom: 35px;

            &:before {
                position: absolute;
                content: '';
                width: 80px;
                height: 80px;
                left: 0;
                right: 0;
                top: 0;
                background-color: $white-color;
                margin-left: auto;
                margin-right: auto;
                transform: rotate(35deg);
                transition: $transition;
            }
            &:after {
                position: absolute;
                content: '';
                width: 80px;
                height: 80px;
                left: 0;
                right: 0;
                top: 0;
                background-color: $white-color;
                margin-left: auto;
                margin-right: auto;
                transform: rotate(55deg);
                opacity: .50;
                transition: $transition;
            }
            i {
                display: inline-block;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 45px;
                z-index: 1;
                position: relative;
                text-align: center;
                margin-top: 15px;
            }
        }
        h3 {
            font-weight: 500;
            font-size: 20px;
            color: $white-color;
            margin-bottom: 12px;
        }
        p {
            color: #dfdfdf;
            font-size: 15px;
            margin-bottom: 15px;
        }
        a {
            display: inline-block;
            font-weight: 600;
            font-size: 15px;
            color: $white-color;

            i {
                display: inline-block;
                font-size: 18px;
                position: relative;
                top: 2px;
                left: 0;
                transition: $transition;
            }
        }
    }
}
/*-- End House --*/

/*-- Address --*/
.two-address-area {
    background-image: url('assets/img/home-two/address-bg.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #edfffb;
    text-align: center;
    position: relative;
    padding-bottom: 190px;

    .one-quote-area {
        position: absolute;
        bottom: -100px;
        right: 0;
        left: 0;
        padding-bottom: 0;

        .row {
            background-color: $white-color;
        }
    }
    .address-item {
        margin-bottom: 30px;

        ul {
            margin: 0;
            padding: 0;
            margin-top: -35px;
            margin-bottom: 15px;

            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 5px;
                margin-left: 5px;

                a {
                    display: block;
                    font-size: 40px;
                    text-align: center;
                    border-radius: 50%;
                    color: $black-color;
                    background-color: $white-color;
                    position: relative;
                    z-index: 1;
                    overflow: hidden;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 0;
                        height: 70px;
                        left: 0;
                        top: 0;
                        background: $gradient-green;
                        border-radius: 50%;
                        z-index: -1;
                        opacity: 0;
                        transition: $transition;
                    }
                    i {
                        width: 70px;
                        height: 70px;
                        line-height: 70px;
                    }
                    &:hover {
                        color: $white-color;

                        &:before {
                            opacity: 1;
                            width: 70px;
                        }
                    }
                }
            }
        }
        h3 {
            font-weight: 700;
            font-size: 28px;
            margin-bottom: 12px;
        }
        p {
            margin-bottom: 0;
        }
    }
}
/*-- End Address --*/

/*-- Team --*/
.two-team-area {
    padding-top: 195px;
}
/*-- End Team --*/

/*-- Help --*/
.two-help-area {
    .help-img-two {
        background-image: url('assets/img/home-two/fun.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;

        img {
            display: none;
        }
    }
    .help-item {
        max-width: 680px;
        margin-left: auto;
        padding-right: 55px;
        padding-top: 100px;
        padding-bottom: 100px;

        ul {
            li {
                flex: 0 0 100%;
                max-width: 100%;

                .help-inner {
                    p {
                        max-width: 285px;
                    }
                }
            }
        }
    }
}
/*-- End Help --*/

/*-- Lover --*/
.two-lover-area {
    .lover-item {
        margin-bottom: 30px;

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            box-shadow: 0px 0px 20px 0px #dddddda3;
            padding: 40px 30px 40px;

            li {
                list-style-type: none;
                display: inline-block;
                flex: 0 0 35%;
                max-width: 35%;

                &:last-child {
                    flex: 0 0 65%;
                    max-width: 65%;
                }
                .lover-img {
                    position: relative;
                    display: inline-block;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 1px;
                        height: 130px;
                        right: -23px;
                        top: 0;
                        background-color: #dddddd;
                    }
                    img {
                        border-radius: 50%;
                        border: 5px solid $white-color;
                        box-shadow: 0px 0px 20px 0px #dddddda3;
                    }
                    i {
                        display: inline-block;
                        color: $white-color;
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        border-radius: 50%;
                        text-align: center;
                        font-size: 20px;
                        background: $gradient-green;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                    }
                }
                .lover-content {
                    h3 {
                        font-weight: 600;
                        font-size: 20px;
                        margin-bottom: 7px;
                    }
                    span {
                        display: block;
                        font-size: 15px;
                        background: $gradient-green;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        margin-bottom: 5px;
                    }
                    p {
                        margin-bottom: 10px;
                    }
                    ul {
                        display: block;
                        margin: 0;
                        padding: 0;
                        box-shadow: none;
                        
                        li {
                            flex: 0 0 100%;
                            max-width: 100%;

                            &:last-child {
                                flex: 0 0 100%;
                                max-width: 100%;
                            }
                            i {
                                display: inline-block;
                            }
                            .checked {
                                color: #ff9c00;
                            }
                        }
                    }
                }
            }
        }
    }
}
/*-- End Lover --*/

/*-- FAQ --*/
.two-faq-area {
    background-color: transparent;
    background-image: unset;

    .faq-img-two {
        margin-bottom: 30px;

        img {
            width: 100%;
        }
    }
    .faq-content {
        margin-bottom: 30px;
    }
}
/*-- End FAQ --*/

/*-- Contact --*/
.two-contact-area {
    background-color: transparent;
    background-image: url('assets/img/home-two/contact-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, rgba(13,24,32,0.17651941535178195) 15%, rgba(13,24,32,0.8900025614191976) 67%);
    }
    .contact-content {
        padding-top: 0;
        padding-bottom: 0;
        margin-left: auto;
        position: relative;

        .one-section-title {
            margin-left: 0;
        }
    }
}
/*-- End Contact --*/

/*-- Blog --*/
.two-blog-area {
    .blog-item {
        background-color: #edfffb;

        &:hover {
            background-color: $white-color;
        }
    }
}
/*-- End Blog --*/

/*-- Subscribe --*/
.two-subscribe-area {
    background-color: #edfffb;

    .subscribe-wrap {
        background-image: unset;
        padding: 0;
        
        &:before {
            display: none;
        }
        h2 {
            color: $black-color;
            margin-bottom: 0;
        }
        .newsletter-form {
            max-width: 100%;
        }
    }
}
/*-- End Subscribe --*/
/*----- End Home Two CSS -----*/

/*----- Home Three CSS -----*/
/*-- Header Top --*/
.two-header-top {
    background-color: $black-color;
    
    .header-partner {
        p {
            color: #b8bcbf;

            a {
                color: $white-color;

                &:hover {
                    color: #12ca59;
                }
            }
        }
    }
    .header-call {
        ul {
            li {
                span {
                    color: #b8bcbf;
                }
                a {
                    color: $white-color;

                    &:hover {
                        color: #12ca59;
                    }
                }
            }
        }
    }
}
/*-- End Header Top --*/

/*-- Navbar --*/
.navbar-light-two {
    box-shadow: 0px 18px 52px 0px #dddddd9e;

    .side-nav {
        .side-nav-right {
            border-radius: 50px;

            &:before {
                border-radius: 50px;
            }
        }
    }
}
/*-- End Navbar --*/

/*-- Banner --*/
.three-banner-area {
    height: 730px;
    padding-left: 40px;
    padding-right: 40px;
    overflow: hidden;
    position: relative;

    .banner-shape {
        img {
            position: absolute;

            &:nth-child(1) {
                left: 0;
                top: 60px;
            }
            &:nth-child(2) {
                left: 0;
                top: 0;
                z-index: -1;
                max-width: 500px;
            }
        }
    }
    .banner-content {
        span {
            display: block;
            font-weight: 500;
            font-size: 25px;
            color: $black-color;
            margin-bottom: 20px;

            img {
                max-width: 50px;
                display: inline-block;
                margin-right: 5px;
            }
        }
        h1 {
            font-weight: 700;
            font-size: 92px;
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 30px;
            font-weight: 500;
            font-size: 17px;
            max-width: 695px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;

                .cmn-banner-btn {
                    margin-right: 25px;
                    border-radius: 50px;
                    overflow: hidden;
                    top: 10px;

                    &:before {
                        border-radius: 50px;
                    }
                    &:hover {
                        color: $white-color;
                    }
                }
                .banner-play {
                    text-align: center;
                    background-color: $white-color;
                    color: #12ca59;
                    font-size: 35px;
                    box-shadow: 0px 0px 20px 0px #dddddd9e;
                    display: inline-block;
                    border-radius: 50%;

                    &:hover {
                        color: $white-color;
                        background-color: #12ca59;
                    }
                    i {
                        width: 65px;
                        height: 65px;
                        line-height: 65px;
                    }
                }
                span {
                    display: inline-block;
                    font-weight: 500;
                    font-size: 20px;
                    color: #333333;
                    margin-bottom: 0;
                    margin-left: 10px;
                    position: relative;
                    top: -7px;
                }
                &:last-child {
                    margin-top: -10px;
                }
            }
        }
    }
    .banner-img {
        img {
            &:nth-child(2) {
                position: absolute;
                left: -18px;
                top: -80px;
                max-width: 115px;
                z-index: -1;
            }
            &:nth-child(3) {
                position: absolute;
                left: -52px;
                top: -142px;
                max-width: 115px;
                z-index: -1;
                transform: rotate(2deg);
            }
        }
    }
}
/*-- End Banner --*/

/*-- Time --*/
.three-banner-wrap {
    .container-fluid {
        position: relative;

        .banner-time-left {
            background-color: $black-color;

            ul {
                li {
                    a {
                        border-bottom: 1px solid $white-color;

                        &:hover {
                            border-bottom: 1px solid #12ca59;
                        }
                    }
                }
            }
        }
        .banner-time-right {
            background-image: url('assets/img/home-three/time-bg.png');
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            padding: 50px 35px 50px 35px;
        }
    }
}
/*-- End Time --*/

/*-- About --*/
.three-about-area {
    padding-top: 150px;
    position: relative;
    
    .about-shape {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
    }
    .about-img {
        position: relative;
        margin-bottom: 30px;
        
        img {
            &:nth-child(2) {
                position: absolute;
                left: -85px;
                top: -50px;
                z-index: -1;
            }
            &:nth-child(3) {
                position: absolute;
                left: -45px;
                top: 0;
                z-index: -1;
            }
        }
        .about-inner {
            background-color: #10c31a;
            display: inline-block;
            position: absolute;
            bottom: 15px;
            right: 0;
            border-radius: 4px;
            max-width: 380px;
            margin-left: auto;
            padding: 20px 30px 30px;

            h3 {
                color: $white-color;
                font-weight: 600;
                font-size: 40px;
                margin-bottom: 5px;
            }
            p {
                margin-bottom: 0;
                font-size: $all-size;
                color: #daffdc;
            }
        }
    }
    .about-content {
        margin-bottom: 30px;

        .two-section-title {
            text-align: left;
            margin-bottom: 35px;
        }
        .about-mission {
            .nav-pills {
                margin-bottom: 25px;

                li {
                    margin-right: 12px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        font-weight: 500;
                        font-size: 15px;
                        color: #626262;
                        background-color: #dfdfdf;
                        border-radius: 30px;
                        display: inline-block;
                        padding: 10px 22px 10px 20px;

                        i {
                            display: inline-block;
                            font-size: 18px;
                            position: relative;
                            top: 2px;
                            margin-right: 2px;
                        }
                    }
                    .nav-default {
                        position: relative;
                        z-index: 1;
                        
                        &:before {
                            position: absolute;
                            content: '';
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 30px;
                            background: $gradient-green;
                            opacity: 0;
                            z-index: -1;
                        }
                        &.active {
                            color: $white-color;
                            
                            &:before {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            .tab-content {
                p {
                    margin-bottom: 12px;
                }
                ul {
                    margin: 0;
                    padding: 0;
                    margin-bottom: 30px;

                    li {
                        list-style-type: none;
                        display: block;
                        font-size: $all-size;
                        color: #444444;
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                        i {
                            display: inline-block;
                            font-size: 22px;
                            position: relative;
                            top: 4px;
                            background: $gradient-green;
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }
                .cmn-banner-btn {
                    border-radius: 30px;
                    color: $white-color;
                    overflow: hidden;

                    &:before {
                        border-radius: 30px;
                    }
                    i {
                        top: 4px;
                    }
                }
            }
        }
    }
}
.two-section-title {
    .sub-title {
        margin-bottom: 7px;
        padding-bottom: 7px;
    
        &:before {
            left: 0;
            right: unset;
            bottom: 0;
            width: 22px;
        }
    }
    h2 {
        font-size: 40px;
    }
}
/*-- End About --*/

/*-- Among --*/
.three-among-area {
    background-image: url('assets/img/home-three/among-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    padding-bottom: 200px;

    &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: .95;
        background-color: $white-color;
    }
    .two-section-title {
        position: relative;
    }
    .among-item {
        background-color: $white-color;
        text-align: center;
    }
    .one-quote-area {
        position: absolute;
        bottom: -100px;
        right: 0;
        left: 0;
        padding-bottom: 0;

        .row {
            background-color: $white-color;
        }
    }
}
/*-- End Among --*/

/*-- Process --*/
.three-process-area {
    padding-top: 195px;
    position: relative;

    .counter-shape {
        img {
            position: absolute;

            &:nth-child(1) {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: -1;
            }
            &:nth-child(2) {
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
            }
        }
    }
    .container-fluid {
        padding-right: 55px;
        padding-left: 55px;
    }
    .process-item {
        box-shadow: 0px 0px 65px 0px #dddddd6e;
        border-radius: 4px;
        position: relative;
        padding: 55px 20px 30px 40px;
        transition: $transition;
        margin-bottom: 85px;
        max-width: 320px;
        background-color: $white-color;
        
        &:nth-child(2) {
            margin-left: 25px;
        }
        &:nth-child(3) {
            margin-bottom: 30px;
        }
        &:hover {
            background-color: #12ca59;

            h3 {
                color: $white-color;
            }
            p {
                color: $white-color;
            }
            &:before {
                border-left: 35px solid #12ca59;
            }
        }
        &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            right: -60px;
            top: 50%;
            border: 25px solid transparent;
            border-left: 35px solid $white-color;
            transform: translateY(-50%);
            transition: $transition;
        }
        .process-span {
            display: inline-block;
            background-color: $white-color;
            position: absolute;
            top: -36px;
            left: 40px;
            width: 70px;
            height: 70px;
            line-height: 70px;
            border-radius: 50%;
            transition: $transition;

            span {
                display: inline-block;
                width: 70px;
                height: 70px;
                line-height: 70px;
                border-radius: 50%;
                box-shadow: 0px 0px 25px 0px #dddddd6e;
                text-align: center;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
                font-size: 26px;
                transition: $transition;
            }
        }
        h3 {
            font-weight: 500;
            font-size: 20px;
            color: #1a1b1e;
            margin-bottom: 6px;
            transition: $transition;
        }
        p {
            margin-bottom: 0;
            color: #848484;
            font-size: $all-size;
            transition: $transition;
        }
    }
    .process-two {
        margin-left: auto;

        &:nth-child(2) {
            margin-right: -25px;
            margin-left: auto;
        }
        &:before {
            transform: rotate(180deg);
            right: unset;
            left: -59px;
            top: 35%;
        }
    }
    .process-img {
        text-align: center;
        margin-bottom: 30px;
        position: relative;

        img {
            &:nth-child(1) {
                margin-left: 40px;
                position: relative;
                z-index: 1;
            }
            &:nth-child(2) {
                position: absolute;
                left: -30px;
                bottom: 30px;
                max-width: 105px;
                z-index: -1;
                margin-left: 40px;
            }
            &:nth-child(3) {
                position: absolute;
                left: -20px;
                bottom: 0;
                max-width: 100px;
                z-index: -1;
                margin-left: 40px;
            }
            &:nth-child(4) {
                position: absolute;
                left: -75px;
                bottom: 8px;
                z-index: -1;
            }
            &:nth-child(5) {
                position: absolute;
                left: -85px;
                bottom: 0px;
                opacity: .20;
            }
            &:nth-child(6) {
                position: absolute;
                right: -110px;
                bottom: 8px;
                z-index: -1;
            }
            &:nth-child(7) {
                position: absolute;
                right: -103px;
                bottom: 0px;
                opacity: .20;
            }
        }
    }
}
/*-- End Process --*/

/*-- Counter --*/
.three-counter-area {
    background-image: url('assets/img/home-three/counter-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .70;
    }
}
/*-- End Counter --*/

/*-- Team --*/
.three-team-area {
    .team-item {
        background-color: #dbf6df;
        border-radius: 4px;
        text-align: center;
        margin-bottom: 30px;
        transition: $transition;

        &:hover {
            background-color: $white-color;
            transform: translate(0, -10px);
            box-shadow: 0px 0px 20px 0px #dddddd96;
        }
        .team-top {
            position: relative;

            img {
                width: 100%;
            }
            ul {
                margin: 0;
                padding: 0;
                border-radius: 4px;
                position: absolute;
                left: 7px;
                right: 0;
                bottom: 0;
                margin-left: auto;
                margin-right: auto;
                max-width: 175px;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-left: -4px;

                    &:nth-child(2) {
                        a {
                            background-color: #3f70ff;
                        }
                    }
                    &:nth-child(3) {
                        a {
                            background-color: #e52f29;
                        }
                    }
                    &:nth-child(4) {
                        a {
                            background-color: #b92722;
                        }
                    }
                    &:nth-child(5) {
                        a {
                            background-color: #11c635;
                        }
                    }
                    a {
                        display: block;
                        background-color: #0331b8;
                        color: $white-color;
                        text-align: center;
                        font-size: 17px;

                        &:hover {
                            transform: translate(0, -8px);
                        }
                        i {
                            width: 35px;
                            height: 35px;
                            line-height: 35px;
                        }
                    }
                }
            }
        }
        .team-bottom {
            position: relative;
            padding: 20px 15px 24px;

            i {
                display: inline-block;
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: 50px;
                color: #b6eecd;
            }
            h3 {
                margin-bottom: 5px;
                color: #08104d;
                font-weight: 500;
                font-size: 20px;
            }
            span {
                display: block;
                font-size: 15px;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
    .team-call {
        text-align: center;

        p {
            display: inline-block;
            font-weight: 600;
            font-size: 18px;
            color: #6f6f78;
            margin-bottom: 0;
            position: relative;
            padding-left: 15px;

            a {
                display: inline-block;
                color: $black-color;
                font-size: 25px;

                &:hover {
                    color: #12ca59;
                }
            }
            i {
                display: inline-block;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 50px;
                position: absolute;
                left: -45px;
                bottom: -1px;
            }
        }
    }
}
/*-- End Team --*/

/*-- Loan --*/
.three-loan-area {
    background-color: $black-color;

    .two-section-title {
        margin-bottom: 30px;

        h2 {
            color: $white-color;
        }
    }
    .sorting-menu {
        text-align: center;
        margin-bottom: 50px;

        ul {
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                font-weight: 500;
                color: #a4bdcf;
                font-size: 30px;
                cursor: pointer;
                margin-left: 25px;
                margin-right: 60px;
                border-bottom: 2px solid transparent;
                position: relative;

                &:last-child {
                    margin-right: 0;
                }
                &.mixitup-control-active {
                    color: $white-color;
                    border-bottom: 2px solid $white-color;

                    i {
                        color: $white-color;
                    }
                }
                i {
                    display: inline-block;
                    color: #888888;
                    font-size: 45px;
                    position: absolute;
                    top: 0px;
                    left: -50px;
                }
            }
        }
    }
    .loan-item {
        background-color: #1f313e;
        border-radius: 4px;
        padding: 30px 25px 30px;
        transition: $transition;
        margin-bottom: 30px;

        &:hover {
            background-color: $white-color;

            span {
                color: #12ca59;
                background-color: #cff3d2;
            }
            h3 {
                color: $black-color;
            }
            p {
                color: $grey-color
            }
            a {
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                i {
                    background: $gradient-green;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
        
        span {
            display: inline-block;
            font-size: 14px;
            color: #b2c4cf;
            border-radius: 4px;
            padding: 10px 12px;
            background-color: #33444f;
            margin-bottom: 15px;
            transition: $transition;
        }
        h3 {
            font-weight: 500;
            font-size: 20px;
            color: $white-color;
            margin-bottom: 10px;
            transition: $transition;
        }
        p {
            margin-bottom: 12px;
            font-size: 15px;
            color: #cad7ff;
            transition: $transition;
        }
        a {
            display: inline-block;
            font-size: 14px;
            color: #eef8ff;

            i {
                display: inline-block;
                position: relative;
                top: 2px;
                font-size: 17px;
                transition: $transition;
            }
        }
    }
}
/*-- End Loan --*/

/*-- FAQ --*/
.three-faq-area {
    position: relative;

    .faq-shape {
        img {
            position: absolute;

            &:nth-child(1) {
                top: 0;
                left: 0;
                z-index: -1;
            }
            &:nth-child(2) {
                bottom: 0;
                right: 0;
            }
            &:nth-child(3) {
                top: 80px;
                left: 0;
            }
        }
    }
    .faq-img-two {
        position: relative;

        img {
            &:nth-child(1) {
                position: relative;
            }
            &:nth-child(2) {
                position: absolute;
                left: -15px;
                top: -13px;
                z-index: -1;
                max-width: 90px;
            }
            &:nth-child(3) {
                position: absolute;
                left: -25px;
                top: -35px;
                z-index: -1;
                max-width: 90px;
            }
        }
    }
}
/*-- End FAQ --*/

/*-- Contact --*/
.three-contact-area {
    background-image: url('assets/img/home-three/contact.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .30;
    }
    .contact-content {
        padding: 50px 35px 50px;
        max-width: 635px;
        margin-left: auto;
        background-color: #fafafa;
        border-radius: 4px;
        position: relative;

        .two-section-title {
            margin-left: 0;

            h2 {
                color: $black-color;
                font-size: 35px;
            }
        }
        .contact-btn {
            border-radius: 30px;
            background-color: $black-color;
            overflow: hidden;
            padding: 11px 14px 12px 22px;

            &:before {
                border-radius: 30px;
            }
            &:hover {  
                color: $white-color;
            }
        }
    }
}
/*-- End Contact --*/

/*-- Review --*/
.three-review-area {
    .three-review-slider {
        .review-item {
            text-align: center;
            margin-top: -13px;

            i {
                display: inline-block;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 85px;
                margin-bottom: 15px;
            }
            h2 {
                font-weight: 500;
                font-size: 25px;
                color: #1a1b1e;
                font-style: italic;
                margin-bottom: 35px;
                font-family: $common-font;
                max-width: 1025px;
                margin-left: auto;
                margin-right: auto;
                line-height: 39px;
            }
            .review-bottom {
                position: relative;
                display: inline-block;
                text-align: left;

                img {
                    position: absolute;
                    top: -6px;
                    left: -75px;
                    max-width: 55px;
                }
                h3 {
                    font-weight: 600;
                    color: #1a1b1e;
                    font-size: $all-size;
                    margin-bottom: 5px;
                }
                span {
                    display: block;
                    font-style: 15px;
                    color: #848484;
                }
            }
        }
    }
    .owl-theme .owl-nav.disabled+.owl-dots {
        margin-top: 35px;
    }
    .owl-theme .owl-dots .owl-dot span {
        width: 11px;
        height: 11px;
        background: transparent;
        border: 1px solid #12ca59;
    }
    .owl-theme .owl-dots .owl-dot.active span {
        width: 15px;
        height: 15px;
        position: relative;

        &::before {
            position: absolute;
            content: '';
            width: 7px;
            height: 6px;
            top: 4px;
            right: 3px;
            border-radius: 50%;
            background-color: #12ca59;
        }
    }
}
/*-- End Review --*/

/*-- Join --*/
.three-join-area {
    .row{
        background-image: url('assets/img/home-three/join-bg.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        padding: 50px 60px 55px 115px;
    
        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: #fff;
            opacity: .80;
            border-radius: 4px;
        }
    }
    .join-item {
        position: relative;

        &:before {
            position: absolute;
            content: '';
            width: 2px;
            height: 100px;
            top: 10px;
            right: 60px;
            background-color: #e1e1e1;
        }
        h3 {
            font-weight: 600;
            font-size: 30px;
            color: #1a1b1e;
            margin-bottom: 15px;
        }
        .cmn-banner-btn {
            font-size: 18px;
            padding: 15px 25px 17px;
            border-radius: 50px;
            overflow: hidden;
            color: $white-color;

            &:before {
                border-radius: 50px;
            }
            i {
                top: 4px;
            }
        }
    }
    .join-two {
        &:before {
            display: none;
        }
    }
}
/*-- End Join --*/

/*-- Subscribe --*/
.three-subscribe-area  {
    padding-top: 50px;
    padding-bottom: 50px;
}
/*-- End Subscribe --*/
/*----- End Home Three CSS -----*/

/*----- Home Four CSS -----*/
/*-- Header Top --*/
.four-header-top {
    .header-partner {
        p {
            a {
                color: transparent;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
    .header-call {
        ul {
            li {
                span {
                    color: transparent;
                    background: $gradient-green;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}
/*-- End Header Top --*/

/*-- Navbar --*/
.navbar-light-four {
    background: $gradient-green;
    position: relative;
    z-index: 1;

    &.sticky-top {
        position: sticky;
        z-index: 1020;
    }
    &:before {
        position: absolute;
        background-image: url(assets/img/home-four/navbar-shape.png);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: '';
        z-index: -1;
        opacity: .20;
    }
    .navbar-nav {
        .nav-link {
            color: $white-color;
            position: relative;

            &:before {
                position: absolute;
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                top: 17px;
                left: -5px;
                background-color: $white-color;
                opacity: 0;
                transition: $transition;
            }
        }
    }
    .navbar-nav .active > .nav-link, .navbar-nav .nav-link.active, .navbar-nav .nav-link.show, .navbar-nav .show > .nav-link {
        color: $white-color;

        &:before {
            opacity: 1;
        }
    }
    .navbar-nav .nav-link:focus, .navbar-nav .nav-link:hover {
        color: $white-color;

        &:before {
            opacity: 1;
        }
    }
    .side-nav {
        .side-nav-left {
            color: $white-color;

            &:hover {
                color: $black-color;
            }
        }
        .side-nav-right {
            color: #12ca59;
            background-color: $white-color;
            border-radius: 50px;

            &:hover {
                color: $white-color;
                background-color: #12ca59;
            }
            &:before {
                display: none;
            }
        }
    }
}
/*-- End Navbar --*/

/*-- Banner --*/
.three-section-title {
    .sub-title {
        &:before {
            display: none;
        }
    }
    h2 {
        font-size: 40px;
    }
}
.four-banner-area {
    // background-image: url('assets/img/home-four/banner-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    &:before {
        position: absolute;
        content: '';
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .50;
    }
    .banner-content {
        span {
            color: $white-color;
        }
        h1 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        ul {
            li {
                span {
                    color: $white-color;
                }
            }
        }
    }
    .banner-form {
        background-color: #fafafa;
        padding: 35px 20px 40px;
        border-radius: 4px;

        .three-section-title {
            text-align: left;
            margin-bottom: 25px;
    
            h2 {
                font-size: 35px;
            }
        }
        ul {
            border-bottom: 0;
            margin-bottom: 15px;

            li {
                a {
                    display: block;
                    color: #12ca59;
                    font-size: $all-size;
                    background-color: #e1f4e3;
                    border-radius: 4px;
                    padding: 10px 15px;
                }
                .nav-default.active {
                    position: relative;
                    z-index: 1;
                    color: $white-color;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: $gradient-green;
                        border-radius: 4px;
                        z-index: -1;
                    }
                }
            }
        }
        form {
            .form-group {
                margin-bottom: 20px;
                position: relative;

                label {
                    margin-bottom: 0;
                    font-size: 20px;
                    position: absolute;
                    top: 11px;
                    left: 12px;
                    color: #7f7f7f;
                }
                .form-control {
                    height: 50px;
                    border-radius: 4px;
                    border: 1px solid #f1f1f1;
                    font-weight: 500;
                    font-size: 15px;
                    padding-left: 40px;
                    width: 100%;
                    color: #7f7f7f;
                    background-color: #fefefe;

                    &:focus {
                        box-shadow: none;
                        border: 1px solid #f1f1f1;
                    }
                }
                ::placeholder {
                    color: #7f7f7f;
                }
                .dropdown {
                    position: relative;

                    a {
                        display: block;
                        height: 50px;
                        border-radius: 4px;
                        color: #7f7f7f;
                        background-color: #fefefe;
                        font-weight: 500;
                        font-size: 15px;
                        border: 1px solid #f1f1f1;
                        text-align: left;
                        padding-left: 40px;
                        line-height: 35px;

                        &::after {
                            border-top: 7px solid;
                            position: absolute;
                            top: 22px;
                            right: 25px;
                        }
                        &:focus {
                            box-shadow: none;
                            border: 1px solid #f1f1f1;
                        }
                    }
                    .dropdown-menu.show {
                        display: block;
                        width: 100%;
                    }
                }
            }
            .cmn-banner-btn {
                border: 0;
                font-size: 18px;
                border-radius: 50px;
                padding: 14px 26px 14px 30px;
                overflow: hidden;
                color: $white-color;

                i {
                    top: 3px;
                }
                &:before {
                    border-radius: 50px;
                }
            }
        }
    }
}
/*-- End Banner --*/

/*-- Time --*/
.four-banner-wrap {
    .container-fluid {
        .banner-time-left {
            padding: 50px 55px 50px;
        }
        .banner-time-right {
            padding: 50px 35px 50px 55px;
        }
    }
}
/*-- End Time --*/

/*-- About --*/
.four-about-area {
    padding-top: 100px;

    .three-section-title {
        text-align: left;
        margin-bottom: 30px;
    }
    .about-content {
        margin-bottom: 30px;
        max-width: 620px;
        margin-left: auto;
        padding-right: 30px;

        .about-mission {
            .nav-pills {
                li {
                    margin-right: 0;

                    a {
                        color: #12ca59;
                        background-color: #e7faf4;
                        border-radius: 4px;
                    }
                    .nav-default {
                        &:before {
                            border-radius: 4px;
                        }
                    }
                }
            }
            .tab-content {
                p {
                    margin-bottom: 25px;
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0;
                    margin-bottom: 20px;

                    li {
                        display: inline-block;
                        flex: 0 0 50%;
                        max-width: 50%;
                        margin-bottom: 0;

                        .about-support {
                            i {
                                display: inline-block;
                                color: #12ca59;
                                font-size: 40px;
                                margin-bottom: 10px;
                                top: 0;
                            }
                            h3 {
                                font-weight: 600;
                                font-size: 20px;
                                margin-bottom: 8px;
                            }
                            p {
                                margin-bottom: 0;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
    .about-img {
        .about-inner {
            background-color: $white-color;
            right: unset;
            left: -45px;
            box-shadow: 0px 0px 20px 0px #dddddd96;

            h3 {
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            p {
                color: #959595;
            }
        }
        img {
            &:nth-child(2) {
                position: absolute;
                left: -35px;
                top: unset;
                bottom: -30px;
                z-index: -1;
            }
        }
    }
}
/*-- End About --*/

/*-- Expect --*/
.four-expect-area {
    background-color: $black-color;

    .three-section-title {
        h2 {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            color: $white-color;
        }
    }
    .expect-item {
        background-color: $white-color;
        position: relative;
        border-radius: 4px;
        padding: 45px 35px 45px 115px;
        transition: $transition;
        margin-bottom: 30px;

        &:hover, &.active {
            background-color: #233644;

            h3 {
                color: $white-color;
            }
            p {
                color: #bedaef;
            }
            a {
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                i {
                    background: $gradient-green;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
        .expect-icon {
            i {
                display: inline-block;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-size: 60px;
                margin-bottom: 30px;
                position: absolute;
                top: 45px;
                left: 35px;
            }
        }
        h3 {
            font-weight: 700;
            font-size: 20px;
            color: #262424;
            margin-bottom: 10px;
            transition: $transition;
        }
        p {
            margin-bottom: 10px;
            font-size: 15px;
            color: #6d807c;
            transition: $transition;
        }
        a {
            display: inline-block;
            font-size: $all-size;
            color: #6d807c;

            i {
                display: inline-block;
                position: relative;
                top: 4px;
                font-size: 18px;
                transition: $transition;
            }
        }
    }
}
/*-- End Expect --*/

/*-- Quote --*/
.four-quote-area {
    padding-top: 100px;
    padding-bottom: 100px;

    .row {
        // background-image: url('assets/img/home-four/quote-bg.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        z-index: 1;

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            z-index: -1;
            left: 0;
            background: linear-gradient(90deg, rgba(16,195,23,1) 45%, rgba(16,195,23,0) 75%);
        }
    }
    .quote-content {
        padding: 28px 35px 35px;
        max-width: 515px;

        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        a {
            background-color: $white-color;
            color: $black-color;

            &:hover {
                color: $white-color;
                background-color: $black-color;
            }
            &:before {
                display: none;
            }
        }
    }
}
/*-- End Quote --*/

/*-- Record --*/
.four-record-area {
    position: relative;
    
    .record-shape {
        img {
            position: absolute;

            &:nth-child(1) {
                bottom: 0;
                right: 0;
            }
            &:nth-child(2) {
                bottom: 100px;
                right: 0;
            }
        }
    }
    .three-section-title {
        text-align: left;
        margin-bottom: 35px;
    }
    .record-img {
        margin-bottom: 30px;
    }
    .record-content {
        margin-bottom: 30px;
        max-width: 535px;
        margin-left: 40px;

        .record-item {
            position: relative;
            margin-bottom: 50px;

            h3 {
                font-weight: 700;
                font-size: 75px;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                position: absolute;
                top: 0;
                left: 0;
                line-height: 65px;
            }
            span {
                display: block;
                font-size: 18px;
                color: $black-color;
                padding-left: 110px;
            }
        }
        .cmn-banner-btn {
            border-radius: 50px;
            overflow: hidden;
            color: $white-color;

            &:before {
                border-radius: 50px;
            }
        }
    }
}
/*-- End Record --*/

/*-- Step --*/
.four-step-area {
    // background-image: url('assets/img/home-four/step-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .50;
    }
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
    .three-section-title {
        position: relative;

        h2 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
    }
    .step-item {
        background-color: $white-color;
        position: relative;
        padding: 55px 15px 25px 25px;
        border-radius: 4px;
        margin-top: 40px;

        &:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: -57px;
            top: 50%;
            border: 25px solid transparent;
            border-right: 35px solid $white-color;
            transform: translateY(-50%);
            transition: 0.5s all ease;
        }
        .step-span {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            box-shadow: 0px 6px 20px 0px #dddddd96;
            text-align: center;
            margin-top: -90px;
            background-color: $white-color;
            margin-bottom: 20px;

            span {
                display: inline-block;
                line-height: 70px;
                width: 70px;
                height: 70px;
                display: inline-block;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
                font-size: 26px;
            }
        }
        h3 {
            color: #1a1b1e;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 8px;
        }
        p {
            color: #848484;
            margin-bottom: 0;
        }
    }
    .step-one {
        margin-top: 80px;
    }
}
/*-- End Step --*/

/*-- Team --*/
.four-team-area {
    .team-item {
        margin-bottom: 30px;
        text-align: center;
        background-color: #f4f4f4;
        padding: 25px 25px 25px;
        border-radius: 4px;
        transition: $transition;

        &:hover {
            background-color: $white-color;
            box-shadow: 0px 0px 20px 0px #dddddd96;
            transform: translate(-10px, 0);
        }
        img {
            margin-bottom: 22px;
        }
        h3 {
            font-weight: 600;
            font-size: 20px;
            color: $black-color;
            margin-bottom: 5px;
            position: relative;

            &:before {
                position: absolute;
                content: '';
                width: 3px;
                height: 40px;
                left: -25px;
                top: 8px;
                background: $gradient-green;
            }
        }
        p {
            margin-bottom: 20px;
            background: $gradient-green;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 15px;
        }
        ul {
            margin: 0;
            padding: 0;

            li {
                list-style-type: none;
                display: inline-block;
                margin: {
                    left: 2px;
                    right: 2px;
                };
                &:nth-child(2) {
                    a {
                        background-color: #3f70ff;
                    }
                }
                &:nth-child(3) {
                    a {
                        background-color: #e52f29;
                    }
                }
                &:nth-child(4) {
                    a {
                        background-color: #b92722;
                    }
                }
                &:nth-child(5) {
                    a {
                        background-color: #11c535;
                    }
                }
                a {
                    color: $white-color;
                    background-color: #0331b8;
                    display: block;
                    border-radius: 50%;
                    font-size: 17px;

                    &:hover {
                        transform: rotateY(360deg);
                    }
                    i {
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                    }
                }
            }
        }
    }
    .team-join {
        text-align: center;

        p {
            margin-bottom: 0;
            font-weight: 600;
            font-size: 18px;
            color: #6f6f78;

            a {
                display: inline-block;
                color: $black-color;
                font-size: 25px;
                border-bottom: 1px solid $black-color;

                &:hover {
                    color: #12ca59;
                    border-bottom: 1px solid #12ca59;
                }
            }
        }
    }
}
/*-- End Team --*/

/*-- Loan --*/
.four-loan-area {
    padding-bottom: 200px;

    .three-section-title {
        h2 {
            color: $white-color;
        }
    }
}
/*-- End Loan --*/

/*-- Join --*/
.four-join-area {
    text-align: center;
    margin-top: -130px;
    padding-bottom: 100px;

    .join-item {
        h3 {
            margin-bottom: 30px;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
        a {
            margin-left: 8px;
            margin-right: 8px;
        }
    }
}
/*-- End Join --*/

/*-- FAQ --*/
.four-faq-area {
    background-image: unset;

    .faq-img-two {
        margin-bottom: 30px;
    }
    .faq-content {
        margin-bottom: 30px;
        max-width: 540px;
        margin-left: auto;

        .accordion {
            li {
                background-color: #d7f2d9;
                border: 0;
                box-shadow: 0px 0px 20px 0px #dddddd61;

                a {
                    padding: 20px 20px 20px;
                    font-size: 19px;
                    color: #12ca59;
    
                    &:after {
                        right: 10px;
                    }
                    &.active {
                        color: $black-color;
                    }
                }
                p {
                    padding: 0px 85px 25px 20px;
                }
            }
        }
    }
}
/*-- End FAQ --*/

/*-- Review --*/
.four-review-area {
    .three-review-slider {
        .review-item {
            i {
                margin-bottom: 0;
                position: absolute;
                left: 0;
                right: 0;
                font-size: 135px;
                top: -22px;
                opacity: .20;
            }
            h2 {
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                max-width: 935px;
                margin-top: 5px;
                line-height: 35px;
            }
        }
    }
}
/*-- End Review --*/

/*-- Blog --*/
.four-blog-area {
    background-color: #dbf6df;
}
/*-- End Blog --*/

/*-- Subscribe --*/
.four-subscribe-area {
    padding-top: 50px;
    padding-bottom: 50px;

    .subscribe-wrap {
        .newsletter-form {
            .form-control {
                border-radius: 50px;
            }
            .subscribe-btn {
                border-radius: 50px;
                overflow: hidden;

                &:before {
                    border-radius: 50px;
                }
            }
        }
    }
}
/*-- End Subscribe --*/

/*-- Map --*/
.four-map-area {
    #map {
        display: block;
        border: 0;
        width: 100%;
        height: 400px;
    }
}
/*-- End Map --*/

/*-- Footer --*/
.four-footer-area {
    .footer-item {
        .footer-address {
            .form-group {
                margin-bottom: 15px;

                .form-control {
                    border-radius: 4px;
                    height: 40px;
                    background-color: #142028;
                    border: 1px solid #273946;
                    font-size: 14px;
                    padding-left: 20px;
                    color: $white-color;

                    &:focus {
                        box-shadow: none;
                        border: 1px solid #273946;
                    }
                }
                ::placeholder {
                    color: #688193;
                }
                textarea {
                    height: auto !important;
                }
            }
            .footer-form-btn {
                background-color: $white-color;
                color: $black-color;
                font-weight: 500;
                font-size: 15px;
                border-radius: 50px;
                padding: 14px 28px;
                border: 0;
                transition: $transition;

                &:hover {
                    color: $white-color;
                    background-color: #12ca59;
                }
            }
        }
    }
}
/*-- End Footer --*/
/*----- End Home Four CSS -----*/

/*----- Home Five CSS -----*/
/*-- Banner --*/
.five-banner-area {
    height: 1000px;
    position: relative;

    .banner-shape {
        img {
            position: absolute;
            z-index: -1;

            &:nth-child(1) {
                top: 0;
                left: 0;
            }
            &:nth-child(2) {
                top: 225px;
                right: 55px;
                max-width: 745px;
                animation: five-banner 5s infinite linear;
            }
        }
    }
    .container-fluid {
        padding-left: 55px;
        padding-right: 55px;
    }
    .banner-content {
        max-width: 750px;

        span {
            display: block;
            font-weight: 500;
            font-size: 25px;
            color: $black-color;
            margin-bottom: 15px;

            img {
                max-width: 50px;
                display: inline-block;
                margin-right: 5px;
            }
        }
        h1 {
            font-weight: 600;
            font-size: 100px;
            color: $black-color;
            margin-bottom: 10px;

            span {
                font-weight: 300;
                font-size: 100px;
                display: inline-block;
                margin-bottom: 0;
            }
        }
        p {
            font-weight: 500;
            font-size: 30px;
            color: #1a1b1e;
            margin-bottom: 40px;
        }
        .banner-form {
            ul {
                border-radius: 4px 4px 0 0;
                display: inline-block;
                box-shadow: 0px -7px 20px 0px #dddddd96;

                li {
                    display: inline-block;

                    .nav-default {
                        display: block;
                        font-size: $all-size;
                        background-color: $white-color;
                        padding: 14px 16px;
                        color: #12ca59;
                        position: relative;
                        z-index: 1;

                        &:before {
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: $gradient-green;
                            opacity: 0;
                            z-index: -1;
                        }
                        &.active {
                            color: $white-color;
                            
                            &:before {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            .tab-content {
                form {
                    padding: 30px 30px 30px;
                    box-shadow: 0px 0px 20px 0px #dddddd96;
                    background-color: $white-color;

                    .form-group {
                        margin-bottom: 15px;

                        .form-control {
                            height: 45px;
                            background-color: #f7f7f7;
                            border: 1px solid #eeeeee;
                            border-radius: 0;
                            font-weight: 500;
                            font-size: 15px;
                            width: 100%;
                            &:focus {
                                border: 1px solid #eeeeee;
                                box-shadow: none;
                            }

                            span {
                                font-weight: 500;
                                font-size: 15px;
                                color: #9d9d9d;
                                margin-bottom: 0;
                                line-height: 30px;
                            }
                            .list {
                                display: block;
                                width: 100%;

                                li {
                                    display: block;
                                }
                            }
                        }
                        ::placeholder {
                            color: #9d9d9d;
                        }
                    }
                    .banner-form-btn {
                        width: 100%;
                        height: 45px;
                        color: $white-color;
                        font-size: 15px;
                        font-weight: 500;
                        transition: $transition;
                        position: relative;
                        z-index: 1;
                        background-color: $black-color;
                        border: 0;

                        &:before {
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background: $gradient-green;
                            z-index: -1;
                            transition: $transition;
                        }
                        &:hover {
                            &:before {
                                width: 0;
                            }
                        }
                    }
                    .banner-call {
                        width: 100%;
                        height: 45px;
                        color: $white-color;
                        font-size: 15px;
                        font-weight: 500;
                        background-color: $black-color;
                        display: block;
                        text-align: center;
                        line-height: 45px;

                        &:hover {
                            background-color: #12ca59;
                        }
                    }
                }
            }
        }
    }
    @keyframes five-banner {
        0% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(0, -25px);
        }
        100% {
            transform: translate(0, 0);
        }
    }
}
/*-- End Banner --*/

/*-- Loan --*/
.five-loan-area {
    .three-section-title {
        h2 {
            color: $white-color;
        }
        p {
            color: #c2d1db;
        }
    }
    .loan-item {
        i {
            font-size: 50px !important;
            margin-bottom: 15px;
            display: inline-block;
            font-size: 35px;
            background: $gradient-green;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        a {
            i {
                font-size: 17px !important;
                -webkit-text-fill-color: unset;
            }
        }
    }
    .owl-theme .owl-dots .owl-dot span {
        width: 11px;
        height: 11px;
        margin: 5px 7px;
        background: transparent;
        border: 1px solid #9fb0d9;
    }
    .owl-theme .owl-dots .owl-dot.active span {
        background: transparent;
        width: 14px;
        height: 14px;
        border: 1px solid $white-color;
        position: relative;
        margin: 3px;

        &:before {
            position: absolute;
            content: '';
            width: 7px;
            height: 7px;
            border-radius: 50%;
            left: 2px;
            top: 3px;
            right: 0;
            background-color: $white-color;
        }
    }
}
/*-- End Loan --*/

/*-- About --*/
.five-about-area {
    padding-top: 100px;

    .three-section-title {
        text-align: left;
        margin-bottom: 30px;
    }
    .about-img {
        img {
            &:nth-child(1) {
                animation: five-about-ani 10s infinite linear;
            }
            &:nth-child(2) {
                position: absolute;
                left: -40px;
                top: unset;
                z-index: -1;
                bottom: -5px;
            }
        }
    }
    @keyframes five-about-ani {
        0% {
            transform: rotateY(0deg);
        }
        50% {
            transform: rotateY(20deg);
        }
        100% {
            transform: rotateY(0deg);
        }
    }
}
/*-- End About --*/

/*-- Quote --*/
.five-quote-area {
    padding-left: 70px;
    padding-right: 70px;

    .three-section-title {
        text-align: left;
        margin-bottom: 0;

        .sub-title {
            font-size: 15px;
        }
        h2 {
            font-size: 27px;
            color: $white-color;
        }
    }
    .quote-wrap {
        background-color: $black-color;
        padding-top: 55px;
        padding-bottom: 55px;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 4px 0 0 4px;

        .quote-btn {
            text-align: right;

            a {
                display: inline-block;
                font-size: $all-size;
                padding: 12px 25px 14px 25px;
                border-radius: 50px;
                overflow: hidden;
                text-align: center;

                i {
                    display: inline-block;
                    font-size: 21px;
                    position: relative;
                    top: 4px;
                    margin-right: 4px;
                }
            }
            .quote-btn-left {
                position: relative;
                z-index: 1;
                color: $white-color;
                background-color: $white-color;
                margin-right: 10px;

                &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: $gradient-green;
                    border-radius: 50px;
                    z-index: -1;
                    transition: $transition;
                }
                &:hover {
                    color: $black-color;

                    &:before {
                        width: 0;
                    }
                }
            }
            .quote-btn-right {
                color: $black-color;
                background-color: $white-color;

                &:hover {
                    color: $white-color;
                    background-color: #12ca59;
                }
            }
        }
    }
    .quote-img {
        background-image: url("assets/img/home-three/time-bg.png");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 20px 0px #dddddd96;
        background-color: $white-color;
        position: relative;

        img {
            position: absolute;
            bottom: 0;
            right: 18px;
        }
    }
}
/*-- End Quote --*/

/*-- Service --*/
.five-service-area {
    background-color: #f3fcf4;

    .three-section-title {
        h2 {
            max-width: 610px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .service-item {
        margin-bottom: 30px;
        background-color: $white-color;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px #dddddd96;
        padding: 50px 50px 50px;
        transition: $transition;

        &:hover {
            transform: translate(0, -10px);
        }
        .service-img {
            text-align: center;

            img {
                width: 250px;
                height: 260px;
                margin-bottom: 30px;
            }
        }
        h3 {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 20px;
        }
        p {
            margin-bottom: 17px;
        }
        ul {
            margin: 0;
            padding: 0;
            margin-bottom: 30px;

            li {
                list-style-type: none;
                display: block;
                margin-bottom: 14px;
                font-size: $all-size;
                color: #1a1b1e;

                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    color: #12ca59;
                    background-color: #dcf7e4;
                    text-align: center;
                    border-radius: 50%;
                    font-size: 18px;
                    margin-right: 10px;
                }
            }
        }
        .cmn-banner-btn {
            border-radius: 50px;
            padding: 12px 23px 14px 29px;
            margin-right: 10px;

            &:before {
                border-radius: 50px;
            }
            i {
                top: 4px;
                margin-right: 0;
            }
        }
        .service-btn {
            border-radius: 50px;
            padding: 12px 23px 14px 29px;
            display: inline-block;
            background-color: #e7faf1;
            color: #12ca59;

            &:hover {
                color: $white-color;
                background-color: $black-color;
            }
            i {
                display: inline-block;
                font-size: 20px;
                position: relative;
                top: 4px;
            }
        }
    }
}
/*-- End Service --*/

/*-- Step --*/
.five-step-area {
    background-image: unset;

    &:before {
        display: none;
    }
    .three-section-title {
        h2 {
            color: $black-color;
        }
        p {
            color: $grey-color;
        }
    }
    .step-item {
        box-shadow: 0px 0px 45px 0px #dddddd96;
        margin-bottom: 30px;
    }
}
/*-- End Step --*/

/*-- Team --*/
.five-team-area {
    background-color: #f2fcf3;
    position: relative;
    
    .team-shape {
        position: absolute;
        top: 0;
        right: 0;
    }
}
/*-- End Team --*/

/*-- Counter --*/
.five-counter-area {
    .row {
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
    }
    .counter-item {
        margin-bottom: 30px;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px #dddddd96;
        text-align: center;
        position: relative;
        padding: 35px 25px 35px;
        max-width: 180px;
        margin-left: auto;
        margin-right: auto;
        transition: $transition;

        &:hover {
            margin-top: 15px;
        }
        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 3px;
            top: 0;
            left: 0;
            background: $gradient-green;
        }
        h3 {
            font-weight: 700;
            font-size: 75px;
            background: $gradient-green;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            line-height: 70px;
            margin-bottom: 10px;
        }
        span {
            display: block;
            font-size: 17px;
            color: #1b2b37;
        }
    }
    .counter-two {
        margin-top: 15px;

        &:hover {
            margin-top: 0;
        }
    }
}
/*-- End Counter --*/

/*-- FAQ --*/
.five-faq-area {
    .faq-content {
        .accordion {
            li {
                background-color: #f5f5f5;

                a {
                    color: #1a1b1e;
                }
            }
        }
    }
}
/*-- End FAQ --*/

/*-- Loan --*/
.five-loan-area {
    padding-bottom: 70px;
}
/*-- End Loan --*/

/*-- Lover --*/
.five-lover-area {
    .three-section-title {
        h2 {
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .lover-item {
        transition: $transition;
        margin-bottom: 70px;

        &:hover {
            background-color: #f7f7f7;
        }
        ul {
            li {
                &:last-child {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                .lover-content {
                    position: relative;

                    .lover-inner-img {
                        position: absolute;
                        bottom: -80px;
                        right: 0;                    

                        img {
                            border-radius: 50%;
                            border: 5px solid $white-color;
                            box-shadow: 0px 0px 20px 0px #dddddda3;
                        }
                    }
                    .lover-icon {
                        position: absolute;
                        top: -10px;
                        right: 0;

                        i {
                            display: inline-block;
                            color: #b2ebd4;
                            font-size: 45px;
                        }
                    }
                }
            }
        }
    }
}
/*-- End Lover --*/

/*-- Blog --*/
.five-blog-area {
    background-color: #dcf8ef;

    .blog-item {
        .blog-bottom {
            ul {
                li {
                    i {
                        background: $gradient-green;
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }
}
/*-- End Blog --*/

/*-- Subscribe --*/
.five-subscribe-area {
    background-color: #15d1a5;
    
    .subscribe-wrap {
        padding-top: 50px;
        padding-bottom: 60px;
        padding-left: 0;
        padding-right: 0;
        background-image: unset;
        text-align: left;

        &:before {
            display: none;
        }
        h2 {
            margin-left: 0;
        }
        .newsletter-form {
            margin-left: 0;

            .form-control {
                border-radius: 50px;
            }
            .subscribe-btn {
                border-radius: 50px;
                overflow: hidden;

                &:before {
                    border-radius: 50px;
                    width: 0;
                }
                &:hover {
                    &:before {
                        width: 100%;
                    }
                }
            }
        }
        .sub-img {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
}
/*-- End Subscribe --*/

/*-- Footer --*/
.five-footer-area {
    // background-image: url('assets/img/home-five/footer-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $white-color;
        opacity: .96;
    }
    .footer-item {
        position: relative;

        .footer-logo {
            p {
                color: $grey-color;
            }
            ul {
                li {
                    a {
                        color: #12ca59;
                        border: 1px solid #12ca59;

                        &:hover {
                            color: $white-color;
                            background-color: #12ca59;
                        }
                    }
                }
            }
        }
        .footer-quick {
            h3 {
                color: $black-color;
            }
            ul {
                li {
                    a {
                        color: $grey-color;
                    }
                }
            }
        }
        .footer-address {
            h3 {
                color: $black-color;
            }
            .form-group {
                .form-control {
                    background-color: $white-color;
                    border: 1px solid #ababab;
                    color: $black-color;
                }
            }
            .footer-form-btn {
                color: $white-color;
                background: $gradient-green;

                &:hover {
                    background: $black-color;
                }
            }
        }
    }
    .copyright-area {
        border-top: 1px solid #e6e6e6;
        position: relative;

        .copyright-item {
            p {
                a {
                    color: #12ca59;
                    border-bottom: 1px solid #12ca59;
                }
            }
        }
    }
}
/*-- End Footer --*/
/*----- End Home Five CSS -----*/

/*----- Home Six CSS -----*/
/*-- Banner --*/
.six-banner-area {
    // background-image: url('assets/img/home-six/banner-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    height: 850px;

    .banner-img {
        position: absolute;
        top: 45px;
        left: -50px;
        max-width: 960px;
    }
    .banner-content {
        position: relative;
        z-index: 1;
        max-width: 500px;

        h1 {
            font-size: 100px;
            font-weight: 700;

            span {
                font-weight: 500;
                margin-bottom: 0;
                font-size: 100px;
            }
        }
    }
}
/*-- End Banner --*/

/*-- Quote --*/
.six-quote-area {
    // background-image: url('assets/img/home-six/quote-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    z-index: -1;
    padding-top: 50px;
    padding-bottom: 55px;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 0;
        left: 0;
        background-color: $black-color;
        opacity: .90;
    }
    .quote-wrap {
        background-color: unset;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
    }
}
/*-- End Quote --*/

/*-- Loan --*/
.six-loan-area {
    background-color: $white-color;

    .loan-item {
        background-color: #dcf7ea;

        i {
            transition: $transition;
        }
        h3 {
            color: #08104d;
        }
        p {
            color: #667c71;
        }
        a {
            color: #13cc70;
        }
        &:hover {
            background-color: #13cc70;

            i {
                color: $white-color;
                -webkit-text-fill-color: unset;
            }
            h3 {
                color: $white-color;
            }
            p {
                color: #d5ffea;
            }
            a {
                color: #eef8ff;
                -webkit-text-fill-color: unset;

                i {
                    color: #eef8ff;
                    -webkit-text-fill-color: unset;
                }
            }
        }
    }
    .owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    border: 1px solid #13cc70;
                }
            }
        }
    }
    .owl-theme {
        .owl-dots {
            .owl-dot.active span {
                border: 1px solid #13cc70;
            }
        }
    }
    .owl-theme {
        .owl-dots {
            .owl-dot.active span:before {
                background-color: #13cc70;
            }
        }
    }
}
/*-- End Loan --*/

/*-- About --*/
.six-about-area {
    padding-top: 20px;

    .about-shape {
        position: absolute;
        top: 0;
        left: 0;
    }
    .about-img {
        img {
            &:nth-child(1) {
                position: relative;
            }
            &:nth-child(2) {
                left: unset;
                right: 0;
                bottom: 0;
                z-index: 1;
            }
        }
    }
}
/*-- End About --*/

/*-- Contact --*/
.six-contact-area {
    // background-image: url('assets/img/home-six/contact-bg.jpg');

    .contact-content {
        position: relative;
        top: 100px;
        background-color: #fefefe;

        .three-section-title {
            margin-left: 0;

            h2 {
                color: $black-color;
                font-size: 35px;
            }
        }
        .form-group {
            .form-control {
                border: 1px solid #d8d8d8;
            }
        }
    }
}
/*-- End Contact --*/

/*-- Counter --*/
.six-counter-area {
    background-image: url('assets/img/home-six/counter-bg.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #f9f9f9;
    padding-top: 200px;

    .counter-item {
        position: relative;
        margin-bottom: 30px;

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;

            li {
                list-style-type: none;
                display: inline-block;
                flex: 0 0 40%;
                max-width: 40%;

                &:last-child {
                    flex: 0 0 55%;
                    max-width: 55%;
                    margin-left: 10px;
                }
            }
        }
        .progress{
            width: 100px;
            height: 100px;
            line-height: 100px;
            background: none;
            box-shadow: none;
            position: relative;
            display: inline-block;

            &:after{
                content: "";
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border: 7px solid #dccec0;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .progress > span{
            width: 50%;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            z-index: 1;
        }
        .progress .progress-left{
            left: 0;
        }
        .progress .progress-bar{
            width: 100%;
            height: 100%;
            background: none;
            border-width: 7px;
            border-style: solid;
            position: absolute;
            top: 0;
        }
        .progress .progress-left .progress-bar{
            left: 100%;
            border-top-right-radius: 80px;
            border-bottom-right-radius: 80px;
            border-left: 0;
            transform-origin: center left;
        }
        .progress .progress-right{
            right: 0;
        }
        .progress .progress-right .progress-bar{
            left: -100%;
            border-top-left-radius: 80px;
            border-bottom-left-radius: 80px;
            border-right: 0;
            transform-origin: center right;
            animation: loading-1 1.8s linear forwards;
        }
        .progress .progress-value{
            width: 90%;
            height: 90%;
            border-radius: 50%;
            background: transparent;
            font-size: 24px;
            color: #fff;
            line-height: 135px;
            text-align: center;
            position: absolute;
            top: 5%;
            left: 5%;
    
            i {
                display: inline-block;
                color: #262424;
                font-size: 48px;
                position: relative;
                top: -10px;
            }
        }
        .progress.blue .progress-bar{
            border-color: #12ca59;
        }
        .progress.blue .progress-left .progress-bar{
            animation: loading-2 1.5s linear forwards 1.8s;
        }
        .progress.yellow .progress-bar{
            border-color: #12ca59;
        }
        .progress.yellow .progress-left .progress-bar{
            animation: loading-3 1s linear forwards 1.8s;
        }
        .progress.pink .progress-bar{
            border-color: #12ca59;
        }
        .progress.pink .progress-left .progress-bar{
            animation: loading-4 0.4s linear forwards 1.8s;
        }
        .progress.green .progress-bar{
            border-color: #12ca59;
        }
        .progress.green .progress-left .progress-bar{
            animation: loading-5 1.2s linear forwards 1.8s;
        }
        @keyframes loading-1{
            0%{
                transform: rotate(0deg);
            }
            100%{
                transform: rotate(180deg);
            }
        }
        @keyframes loading-2{
            0%{
                transform: rotate(0deg);
            }
            100%{
                transform: rotate(144deg);
            }
        }
        @keyframes loading-3{
            0%{
                transform: rotate(0deg);
            }
            100%{
                transform: rotate(90deg);
            }
        }
        @keyframes loading-4{
            0%{
                transform: rotate(0deg);
            }
            100%{
                transform: rotate(36deg);
            }
        }
        @keyframes loading-5{
            0%{
                transform: rotate(0deg);
            }
            100%{
                transform: rotate(126deg);
            }
        }
        @media only screen and (max-width: 990px){
            .progress{ margin-bottom: 20px; }
        }
        .progress-content {
            display: inline-block;
            h3 {
                font-weight: 600;
                font-size: 35px;
                color: #262424;
                margin-bottom: 0px;
            }
            p {
                margin-bottom: 0;
                color: #262424;
                font-size: 17px;
                text-transform: uppercase;
            }
        }
    }
}
/*-- End Counter --*/

/*-- Step --*/
.six-step-area {
    .three-section-title {
        h2 {
            max-width: 470px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 11px;
                    height: 11px;
                    margin: 5px 7px;
                    background: transparent;
                    border: 1px solid #13cc70;
                }
            }
            .owl-dot.active span {
                background: transparent;
                width: 14px;
                height: 14px;
                border: 1px solid #13cc70;
                position: relative;
                margin: 3px;
            }
            .owl-dot.active span:before {
                position: absolute;
                content: '';
                width: 7px;
                height: 7px;
                border-radius: 50%;
                left: 2px;
                top: 3px;
                right: 0;
                background-color: #13cc70;
            }
        }
    }
}
/*-- End Step --*/

/*-- Nearly --*/
.six-nearly-area {
    // background-image: url('assets/img/home-six/nearly-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        z-index: -1;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $white-color;
        opacity: .90;
    }
    .three-section-title {
        h2 {
            color: $black-color;
            max-width: 610px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
        
        }
    }
    .nearly-content {
        background-color: $white-color;
        border-radius: 4px;
        box-shadow: 0px 0px 20px 0px #dddddd96;
        
        .nav-tabs {
            border-bottom: 1px solid #f3f3f3;
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 55px;

            .nav-default {
                font-weight: 500;
                font-size: 18px;
                color: #a6a6a6;
                font-family: $heading-font;
                padding-right: 15px;
                margin-right: 15px;
                border-right: 1px solid #a6a6a6;
                display: inline-block;

                &:last-child {
                    border-right:0;
                }
                &.active {
                    background: $gradient-green;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
        .tab-content {
            padding-left: 55px;
            padding-top: 25px;
            padding-right: 100px;
            padding-bottom: 40px;

            h3 {
                font-weight: 600;
                font-size: 20px;
                color: #1b2b37;
                margin-bottom: 10px;
            }
            p {
                margin-bottom: 15px;
            }
            ul {
                margin: 0;
                padding: 0;
                margin-bottom: 30px;

                li {
                    list-style-type: none;
                    display: block;
                    margin-bottom: 15px;
                    color: #1a1b1e;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    i {
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 50%;
                        text-align: center;
                        color: #12ca59;
                        background-color: #dcf7e2;
                        margin-right: 5px;
                        font-size: 18px;
                    }
                }
            }
            .nearly-btn {
                .cmn-banner-btn {
                    border-radius: 50px;
                    padding: 12px 20px 14px 28px;
                    overflow: hidden;
                    margin-right: 10px;

                    &:before {
                        border-radius: 50px;
                    }
                }
                .nearly-btn {
                    display: inline-block;
                    color: #12ca59;
                    background-color: #e7f9ec;
                    border-radius: 50px;
                    padding: 12px 20px 14px 28px;
                    overflow: hidden;

                    &:hover {
                        color: $white-color;
                        background-color: #12ca59;
                    }
                    i {
                        display: inline-block;
                        font-size: 20px;
                        position: relative;
                        top: 5px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
    .nearly-img {
        // background-image: url('assets/img/home-six/nearly-main.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;

        img {
            display: none;
        }
    }
}
/*-- End Nearly --*/

/*-- Team --*/
.six-team-area {
    .team-item {
        padding: 0;

        &:hover {
            .team-bottom {
                h3 {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
        img {
            margin-bottom: 0;
            width: 100%;
        }
        .team-bottom {
            padding: 25px 25px 25px;

            h3 {
                &:before {
                    width: 50px;
                    height: 3px;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    top: -25px;
                    opacity: 0;
                    transition: $transition;
                }
            }
        }
    }
}
/*-- End Team --*/

/*-- Loan --*/
.seven-loan-area {
    // background-image: url('assets/img/home-six/loan-bg.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        z-index: -1;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: .80;
    }
    .three-section-title {
        h2 {
            color: $white-color;
            position: relative;
        }
    }
}
/*-- End Loan --*/

/*-- FAQ --*/
.six-faq-area {
    .faq-shape {
        position: absolute;
        top: 100px;
        right: 0;
    }
    .faq-img {
        position: relative;
        margin-bottom: 30px;

        img {
            &:nth-child(1) {
                position: relative;
            }
            &:nth-child(2) {
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
            }
            &:nth-child(3) {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
    }
    .faq-content {
        margin-left: 0;
    }
}
/*-- End FAQ --*/

/*-- Lover --*/
.six-lover-area {
    .three-section-title {
        margin-bottom: 35px;
    }
    .lover-item {
        margin-bottom: 30px;
        margin-top: 15px;
    }
    .owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 11px;
                    height: 11px;
                    margin: 5px 7px;
                    background: transparent;
                    border: 1px solid #13cc70;
                }
            }
            .owl-dot.active span {
                background: transparent;
                width: 14px;
                height: 14px;
                border: 1px solid #13cc70;
                position: relative;
                margin: 3px;
            }
            .owl-dot.active span:before {
                position: absolute;
                content: '';
                width: 7px;
                height: 7px;
                border-radius: 50%;
                left: 2px;
                top: 3px;
                right: 0;
                background-color: #13cc70;
            }
        }
    }
}
/*-- End Lover --*/

/*-- Subscribe --*/
.six-subscribe-area {
    background-color: $white-color;
    margin-top: 25px;

    .subscribe-wrap {
        // background-image: url('assets/img/home-six/sub-bg.png');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        padding-left: 50px;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #12bc67;
            opacity: .60;
            display: block;
        }
        h2 {
            max-width: 465px;
        }
        .newsletter-form {
            max-width: 490px;
        }
        .sub-img {
            right: 50px;
        }
    }
}
/*-- End Subscribe --*/

/*-- Hour --*/
.six-hour-area {
    padding-top: 40px;
    padding-bottom: 10px;
    padding-left: 115px;
    padding-right: 50px;

    .hour-item {
        margin-bottom: 30px;
        position: relative;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            width: 1px;
            height: 62px;
            right: 0;
            top: 0;
            background-color: #cdcdcd;
        }
        i {
            display: inline-block;
            color: #262424;
            font-size: 35px;
            position: absolute;
            top: 0;
            left: 0;
        }
        .hour-inner {
            padding-left: 50px;

            h3 {
                font-weight: 600;
                font-size: 20px;
                margin-bottom: 5px;
                background: $gradient-green;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            span {
                display: block;
                font-weight: 500;
                color: #2e2e2e;
                font-size: 16px;
            }
            a {
                display: inline-block;
                font-weight: 500;
                color: #2e2e2e;
                font-size: 16px;
                border-bottom: 1px solid #2e2e2e;

                &:hover {
                    color: #12ca59;
                    border-bottom: 1px solid #12ca59;
                }
            }
        }
    }
    .hour-right {
        &:before {
            display: none;
        }
    }
}
/*-- End Hour --*/
/*----- End Home Six CSS -----*/

/*----- Preloader CSS -----*/
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: $gradient-green;
}
.spinner {
    width: 40px;
    height: 40px;
    background-color: $white-color;
    margin: 100px auto;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}
@keyframes sk-rotateplane {
    0% { 
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    } 
    50% { 
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    } 
    100% { 
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
/*----- End Preloader CSS -----*/

/*----- Back to Top CSS ------*/
#toTop {
	position: fixed;
	bottom: 30px;
    right: 0;
	cursor: pointer;
    display: none;
    z-index: 10;
}
.back-to-top-btn {
    i {
        background: #12ca59;
        color: $white-color;
        height: 50px;
        width: 50px;
        line-height: 50px;
        display: inline-block;
        text-align: center;
        font-size: 25px;
        border-radius: 50%;
        transition: $transition;
        margin-right: 28px;
        box-shadow: 0px 0px 14px 0px #12ca59;

        &:hover {
            background: $black-color;
            color: $white-color;
            box-shadow: 0px 0px 14px 0px #333333a6;
        }
    }
}
/*----- End Back to Top CSS -----*/

/*----- main-section-title CSS -----*/
.main-section-title {
    margin-bottom: 45px;
    text-align: center;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 12px;
    }
    h2 {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 0;
    }
}

/*----- main-default-btn CSS -----*/
.main-default-btn {
    display: inline-block;
    padding: 15px 30px;
    background-color: $main-color;
    color: $white-color;
    border: none;
    font-size: 15px;
    font-weight: 400;
    transition: $transition;
    border-radius: 10px;

    &:hover {
        background-color: $black-color;
    }
}

/*----- Main Top Header Area CSS -----*/
.main-top-header {
    background-color: $black-color;
    padding: {
        top: 15px;
        bottom: 15px;
    };
    .container-fluid {
        padding: {
            left: 30px;
            right: 30px;
        };
    }
}
.top-header-information {
    padding: 0;
    margin-bottom: 0;

    li {
        list-style-type: none;
        display: inline-block;
        margin-right: 20px;
        font-size: 14px;

        &:last-child {
            margin-right: 0;
        }
        span {
            color: $white-color;
            font-weight: 600;
            font-size: 14px;
        }
        a {
            color: #DDDDDD;

            &:hover {
                color: $main-color;
            }
        }
    }
}
.top-header-social {
    padding: 0;
    margin-bottom: 0;
    text-align: end;

    li {
        list-style-type: none;
        display: inline-block;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
        a {
            i {
                display: inline-block;
                height: 30px;
                width: 30px;
                line-height: 30px;
                background-color: $white-color;
                color: $black-color;
                text-align: center;
                border-radius: 50px;
                font-size: 15px;
                transition: $transition;

                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*----- Main Navbar Area CSS -----*/
.main-navbar {
    .navbar {
        
        &.is-sticky {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 999;
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
            background-color: $white-color !important;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;   
        }
        &.navbar-light {
            padding: {
                left: 0;
                right: 0;
                top: 15px;
                bottom: 15px;
            };
            background-color: $white-color;
            box-shadow: 0px 5px 50px rgba(13, 24, 32, 0.05);

            .navbar-nav {
                margin: auto;
                .nav-item {
                    position: relative;
                    padding: 0;
                    a {
                        color: $black-color;
                        padding: {
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                        }
                        font-weight: 400;
                        margin: {
                            left: 20px;
                            right: 20px;
                        }
                        &:hover, &:focus, &.active {
                            color: $main-color;
                            position: relative;
                            transition: $transition;
                        }
                    }
                    &:last-child {
                        a {
                            margin-right: 0;
                        }
                    }
                    &:first-child {
                        a {
                            margin-left: 0;
                        }
                    }
                    &:hover, &.active {
                        a {
                            color: $main-color;
                        }
                    }
                }
            }
            .others-option {
                .option-item {
                    margin-right: 25px;

                    &:last-child {
                        margin-right: 0;
                    }
                    .log-in {
                        color: $black-color;
                        font-weight: 400;
                        transition: $transition;

                        &:hover {
                            color: $main-color;
                        }
                    }
                    .sign-up {
                        display: inline-flex;
                        padding: 15px 25px;
                        background: $main-gradient-color;
                        color: $white-color;
                        font-weight: 400;
                        transition: $transition;
                        border-radius: 5px;
                        position: relative;
                        z-index: 1;
                        overflow: hidden;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: 50%;
                            width: 550px;
                            height: 550px;
                            margin: auto;
                            background-color: $main-color;
                            border-radius: 50%;
                            z-index: -1;
                            transform-origin: top center;
                            transform: translateX(-50%) translateY(-5%) scale(.4);
                            transition: transform .9s;
                        }
                        &:hover {
                            color: $white-color;
                    
                            &:before {
                                transition: transform 1s;
                                transform: translateX(-45%) translateY(0) scale(1);
                                transform-origin: bottom center;
                            }
                        }
                    }
                    .call-btn {
                        color: $black-color;
                        font-weight: 400;
                        position: relative;
                        top: 1px;

                        &:hover {
                            color: $main-color;
                        }
                    }
                }
            }
        }
        .container-fluid {
            padding: {
                left: 30px;
                right: 30px;
            };
        }
        &.navbar-style-two {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 9;
            background-color: transparent !important;
            box-shadow: unset;

            &.is-sticky {
                box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
                background-color: $white-color !important;

                &.navbar-light {
                    .others-option {
                        .option-item {
                            .log-in {
                                color: $black-color;
    
                                &:hover {
                                    color: $main-color;
                                }
                            }
                            .sign-up {
                                background: $main-gradient-color;
                                color: $white-color;
    
                                &::before {
                                    background-color: $black-color;
                                }
                            }
                            .call-btn {
                                color: $black-color;
    
                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }
                    }
                }
            }
            &.navbar-light {
                .logo {
                    padding: 0 55px 0 0;
                }
                .navbar-nav {
                    margin-right: auto;
                    margin-left: 0;
                }
                .others-option {
                    .option-item {
                        .log-in {
                            color: $white-color;
                            font-weight: 500;

                            &:hover {
                                color: $black-color;
                            }
                        }
                        .sign-up {
                            background: $white-color;
                            color: $main-color;

                            &::before {
                                background-color: $black-color;
                            }
                        }
                        .call-btn {
                            color: $white-color;
                            font-weight: 500;

                            &:hover {
                                color: $black-color;
                            }
                        }
                    }
                }
            }
        }
        &.navbar-style-black-color {
            &.navbar-light {
                background-color: $black-color !important;

                .navbar-nav {
                    .nav-item {
                        a {
                            color: $white-color;

                            &:hover, &:focus, &.active {
                                color: $main-color;
                            }
                        }
                    }
                }
                .others-option {
                    .option-item {
                        .log-in {
                            color: $white-color;

                            &:hover {
                                color: $main-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*----- Main Header Area CSS -----*/
.main-header-area {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9;

    .main-top-header {
        background-color: transparent;
    }
    .main-navbar {
        .container-fluid {
            padding: {
                left: 30px;
                right: 30px;
            };
        }
        .navbar {
            &.is-sticky {
                &.navbar-light {
                    border-radius: 0;
                    padding: {
                        left: 30px;
                        right: 30px;
                    };
                }
            }
            &.navbar-light {
                padding: {
                    left: 20px;
                    right: 20px;
                    top: 15px;
                    bottom: 15px;
                };
                border-radius: 5px;
            }
        }
    }
}

/*----- Main Banner Area CSS -----*/
.main-banner-area {
    // background-image: url(assets/img/more-home/banner/banner-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: {
        top: 100px;
        bottom: 100px;
    };
    .container-fluid {
        padding: {
            left: 30px;
            right: 30px;
        };
    }
}
.main-banner-content {
    .sub-title {
        display: inline-block;
        margin-bottom: 18px;
        color: $main-color;
    }
    h1 {
        font-size: 85px;
        margin-bottom: 15px;
        font-weight: bold;
    }
    .banner-popup {
        margin-top: 20px;
        position: relative;
        left: 5px;

        .video-btn {
            i {
                width: 50px;
                height: 50px;
                line-height: 50px;
                display: inline-block;
                text-align: center;
                border-radius: 50%;
                background: $main-gradient-color;
                color: $white-color;
                font-size: 30px;
                margin-right: 10px;
                transition: $transition;
            }
            &:hover {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
            span {
                color: $black-color;
                font-weight: 500;
                font-size: 14px;
                position: relative;
                top: -5px;
            }
        }
    }
}
.main-banner-form-wrap {
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid $white-color;
    box-sizing: border-box;
    box-shadow: 0px 5px 50px rgba(13, 24, 32, 0.05);
    border-radius: 10px;
    padding: 50px 65px;
    max-width: 550px;
    margin-left: auto;

    h3 {
        font-size: 24px;
        font-weight: 600;
        color: $white-color;
        margin-bottom: 25px;
    }
    form {
        .form-group {
            margin-bottom: 20px;

            label {
                display: block;
                margin-bottom: 10px;
                font-weight: 500; 
                color: $white-color;
                font-size: 15px;
            }
            .nice-select {
                float: unset;
                height: 55px !important;
                line-height: 55px;
                color: $paragraph-color;
                font-size: 14px;
                font-weight: 500;
                border-radius: 10px;
                padding: {
                    top: 0;
                    bottom: 0;
                    left: 20px;
                    right: 35px;
                };
                .list {
                    background: $main-gradient-color;
                    border-radius: 10px;
                    margin-top: 0;
                    width: 100%;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    
                    .option {
                        transition: $transition;
                        padding: {
                            left: 20px;
                            right: 20px;
                        };
                        color: $white-color;
                        font-weight: 500;
                        &:hover {
                            background-color: $main-color !important;
                            color: $white-color;
                        }
                        &.selected {
                            background-color: transparent;
                            font-weight: 400;
                        }
                    }
                }
                &:after {
                    right: 20px;
                    width: 8px;
                    height: 8px;
                    margin-top: -5.5px;
                }
            }
        }
        .calculat-button {
            text-align: center;
            margin-top: 30px;

            .default-btn {
                display: inline-block;
                padding: 15px 30px;
                background-color: $main-color;
                color: $white-color;
                width: 100%;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;

                &:hover {
                    background-color: $black-color;
                }
            }
        }
    }
}

/*----- About Style Area CSS -----*/
.about-style-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    &.with-black-color {
        background-color: $black-color;

        .about-style-wrap-content {
            h3 {
                color: $white-color;
            }
            .bold {
                color: $white-color;
            }
            .about-list-tab {
                .nav {
                    border-bottom: 1px solid #F8FAFB;

                    .nav-item {
                        .nav-link {
                            color: $white-color;
                        }
                    }
                }
                .tab-content {
                    .tab-pane {
                        .content {
                            p {
                                color: $white-color;
                            }
                            .list {
                                li {
                                    color: $white-color;
                                }
                            }
                            .tab-btn {
                                margin-top: 25px;

                                .main-default-btn {
                                    color: $white-color;

                                    &:hover {
                                        background-color: $white-color;
                                        color: $main-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .information-card {
            .icon {
                i {
                    background: rgba(0, 193, 149, 0.2);
                }
            }
            p {
                span {
                    color: $white-color;

                    a {
                        color: $white-color;

                        &:hover {
                            color: $main-color;
                        }
                    }
                }
            }
            &:hover {
                .icon {
                    i {
                        background: $main-color;
                    }
                }
            }
        }
    }
}
.about-style-image {
    position: relative;
    z-index: 1;
    padding: {
        top: 15px;
        bottom: 15px;
        left: 15px;
    };
    border-radius: 10px;

    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 312px;
        background-color: transparent;
        left: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 5px;
        border: 1px solid $main-color;
    }
    img {
        border-radius: 10px;
    }
    .quote-content {
        background-color: $main-color;
        padding: 25px;
        position: absolute;
        left: 0;
        bottom: 30px;
        max-width: 418px;
        border-radius: 0px 20px 20px 0px;
        z-index: 1;

        p {
            color: $white-color;
            font-size: 15px;
        }
        span {
            font-weight: 600;
            color: $white-color;
        }
        &::before {
            content: "\ee32";
            font-family:'boxicons';
            font-size: 30px;
            color: $white-color;
            z-index: -1;
            position: absolute;
            right: 25px;
            bottom: 25px;
            line-height: 1;
        }
    }
}
.about-style-content {
    padding-left: 30px;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 18px;
        font-weight: bold;
    }
    .bold {
        color: $black-color;
        font-weight: 500;
    }
    .counter-box {
        background: #e5f9f4;
        border-radius: 10px;
        text-align: center;
        padding: 30px 25px;
        margin-top: 15px;
        position: relative;
        z-index: 1;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 0%;
            height: 0%;
            transition: all 0.5s ease-in-out;
            background: $main-gradient-color;
            z-index: -1;
            opacity: 0;
            border-radius: 50%;
            transform: scale(1.5);
        }
        h4 {
            color: $main-color;
            font-size: 65px;
            line-height: 1;
            font-weight: bold;
            transition: $transition;
            margin-bottom: 15px;
        }
        span {
            color: $black-color;
            font-weight: 600;
            transition: $transition;
        }
        &:hover {
            h4 {
                color: $white-color;
            }
            span {
                color: $white-color;
            }
            &::before {
                opacity: 1;
                visibility: visible;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.about-style-image-wrap {
    position: relative;
    z-index: 1;
    text-align: center;

    .certified-image {
        position: absolute;
        right: 75px;
        bottom: 0;
    }
}
.about-style-wrap-content {
    padding-left: 30px;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 18px;
        font-weight: bold;
    }
    .bold {
        color: $black-color;
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 0;
    }
    .about-list-tab {
        margin-top: 25px;

        .nav {
            padding: 0;
            margin-bottom: 0;
            border: none;
            border-bottom: 1px solid #DDDDDD;

            .nav-item {
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
                .nav-link {
                    transition: $transition;
                    color: $black-color;
                    padding: 0;
                    background-color: transparent;
                    border: none;
                    font-weight: 500;
                    font-size: 15px;
                    position: relative;
                    padding-bottom: 20px;

                    &::before {
                        position: absolute;
                        content: "";
                        height: 1px;
                        width: 0%;
                        background-color: $main-color;
                        left: 0;
                        bottom: 0;
                        opacity: 0;
                        visibility: hidden;
                        transition: $transition;
                    }
                    &:hover, &.active {
                        color: $main-color;

                        &::before {
                            width: 100%;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
        .tab-content {
            .tab-pane {
                .content {
                    margin-top: 25px;
                    
                    .list {
                        padding: 0;
                        margin-bottom: 0;

                        li {
                            list-style-type: none;
                            color: $black-color;
                            font-weight: 400;
                            font-size: $all-size;
                            margin-bottom: 18px;
                            position: relative;
                            padding-left: 22px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                            i {
                                position: absolute;
                                color: $main-color;
                                font-size: 18px;
                                left: 0;
                                top: -2px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.about-style-shape-1 {
    position: absolute;
    right: 0;
    top: -50px;
    z-index: -1;
}
.about-style-area-with-full-width {
    .container-fluid {
        padding-right: var(--bs-gutter-x,.75rem);
        padding-left: var(--bs-gutter-x,.75rem);
    }
}
.about-style-full-content {
    max-width: 640px;
    margin-left: auto;
    padding-right: 25px;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 18px;
        font-weight: bold;
    }
    .bold {
        color: $black-color;
        font-weight: 500;
        font-size: 15px;
    }
    .inner-content {
        margin-top: 25px;

        h4 {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 14px;
        }
        p {
            margin-bottom: 0;
            font-size: 15px;
        }
    }
    .about-btn {
        margin-top: 25px;
        
        .main-default-btn {
            color: $white-color;
        }
    }
}
.about-full-image-wrap {
    position: relative;

    .counter-box {
        background: rgba(13, 24, 32, .9);
        border-radius: 0;
        text-align: center;
        padding: 30px 25px;
        position: absolute;
        left: 20px;
        bottom: 20px;
        z-index: 1;
        overflow: hidden;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 0%;
            height: 0%;
            transition: all 0.5s ease-in-out;
            background: $main-gradient-color;
            z-index: -1;
            opacity: 0;
            border-radius: 50%;
            transform: scale(1.5);
        }
        h4 {
            color: $white-color;
            font-size: 55px;
            line-height: 1;
            font-weight: bold;
            transition: $transition;
            margin-bottom: 15px;
        }
        span {
            color: $white-color;
            font-weight: 600;
            transition: $transition;
        }
        &:hover {
            h4 {
                color: $white-color;
            }
            span {
                color: $white-color;
            }
            &::before {
                opacity: 1;
                visibility: visible;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.about-mortgage-calculator-form {
    background: $main-color;
    box-shadow: 0px 5px 50px rgba(13, 24, 32, 0.05);
    border-radius: 10px;
    padding: 55px 65px;
    border-radius: 10px;

    h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 25px;
        color: $white-color;
    }
    form {
        .form-group {
            margin-bottom: 20px;
            position: relative;

            label {
                margin-bottom: 0;
                font-size: 18px;
                position: absolute;
                top: 11px;
                left: 15px;
                color: #555555;
            }
            .form-control {
                height: 50px;
                border-radius: 4px;
                border: 1px solid #E3E9ED;
                font-weight: 400;
                font-size: 14px;
                padding-left: 40px;
                width: 100%;
                color: #555555;
                background-color: #F8FAFB;

                &:focus {
                    box-shadow: none;
                    border: 1px solid #f1f1f1;
                }
            }
            &::placeholder {
                color: #7f7f7f;
            }
            .dropdown {
                position: relative;

                a {
                    display: block;
                    height: 50px;
                    border-radius: 4px;
                    color: #555555;
                    background-color: #F8FAFB;
                    font-weight: 400;
                    font-size: 14px;
                    border: 1px solid #E3E9ED;
                    text-align: left;
                    padding-left: 40px;
                    line-height: 35px;

                    &::after {
                        border-top: 7px solid;
                        position: absolute;
                        top: 22px;
                        right: 18px;
                    }
                    &:focus {
                        box-shadow: none;
                        border: 1px solid #f1f1f1;
                    }
                }
                .dropdown-menu.show {
                    display: block;
                    width: 100%;
                }
            }
        }
        .main-default-btn {
            border: 0;
            width: 100%;
            background-color: $black-color;

            &:hover {
                background-color: $white-color;
                color: $main-color;
            }
        }
    }
}

/*----- Core Service Area CSS -----*/
.core-service-area {
    // background-image: url(assets/img/more-home/core-service/core-service-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &.with-black-bg {
        // background-image: url(assets/img/more-home/core-service/core-service-bg-2.jpg);

        .main-section-title {
            h2 {
                color: $white-color;
            }
        }
        .core-service-list-tab {
            .nav {
                .nav-item {
                    &:first-child {
                        .nav-link {
                            border-radius: 0;
                        }
                    }
                    &:last-child {
                        .nav-link {
                            border-radius: 0;
                        }
                    }
                }
            }
            .tab-content {
                .tab-pane {
                    border-radius: 0;
                }
            }
        }
    }
}
.core-service-list-tab {
    .nav {
        padding: 0;
        list-style-type: none;
        display: block;
        border: none;
        text-align: center;
        margin-bottom: 0;

        .nav-item {
            display: inline-block;
            margin-right: -5px;
            
            &:first-child {
                .nav-link {
                    border-radius: 8px 0 0 0;
                }
            }
            &:last-child {
                .nav-link {
                    border-radius: 0 8px 0 0;
                }
            }
            .nav-link {
                color: $white-color;
                border: none;
                padding: 20px 30px;
                background-color: $main-color;
                font-weight: 400;
                border-radius: 0;
                transition: $transition;
                
                &:hover, &.active {
                    color: $main-color;
                    background-color: $white-color;
                }
            }
        }
    }
    .tab-content {
        .tab-pane {
            background-color: $white-color;
            border-radius: 10px;
            padding: 50px;
        }
    }
    .core-service-content {
        padding-right: 30px;

        .icon {
            margin-bottom: 20px;

            i {
                display: inline-block;
                height: 70px;
                width: 70px;
                line-height: 70px;
                background-color: #e5f9f4;
                color: $main-color;
                text-align: center;
                font-size: 35px;
                border-radius: 50px;
                transition: $transition;

                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
        h3 {
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 0;
        }
        .list {
            padding: 0;
            margin: {
                top: 20px;
                bottom: 0;
            };
            li {
                list-style-type: none;
                margin-bottom: 15px;
                position: relative;
                color: $black-color;
                padding-left: 22px;
                font-size: 17.5px;

                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    color: $main-color;
                }
            }
        }
    }
    .core-service-image {
        overflow: hidden;
        border-radius: 10px;

        img {
            border-radius: 10px;
            transition: $transition;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }
}

/*----- Buying Home Area CSS -----*/
.buying-home-area {
    overflow: hidden;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        };
    }
}
.buying-home-image {
    position: relative;
    z-index: 1;
    padding: {
        top: 15px;
        bottom: 15px;
        right: 15px;
    };
    img {
        border-radius: 0px 10px 10px 0px;
    }
    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 312px;
        background-color: transparent;
        right: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 10px;
        border: 1px solid $main-color;
    }
    .counter-box {
        background: $main-color;
        border-radius: 10px 0px 0px 10px;
        text-align: center;
        padding: 30px;
        position: absolute;
        right: 0;
        bottom: 45px;

        h4 {
            color: $white-color;
            font-size: 65px;
            line-height: 1;
            font-weight: bold;
            transition: $transition;
            margin-bottom: 15px;
        }
        span {
            color: $white-color;
            font-weight: 600;
            transition: $transition;
        }
        &:hover {
            h4 {
                color: $white-color;
            }
            span {
                color: $white-color;
            }
            
        }
    }
}
.buying-home-content {
    padding-left: 15px;
    max-width: 750px;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 18px;
        font-weight: bold;
    }
    p {
        margin-bottom: 0;
    }
    .buying-inner-box {
        margin-top: 30px;
        position: relative;
        padding-left: 100px;

        .icon {
            position: absolute;
            left: 0;
            top: 1.5px;

            i {
                display: inline-block;
                width: 80px;
                height: 75px;
                line-height: 75px;
                border-radius: 10px;
                background: #e5f9f4;
                color: $main-color;
                font-size: 45px;
                text-align: center;
                transition: $transition;
            }
        }
        h4 {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 0;
            font-size: 15px;
        }
        &:hover {
            .icon {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*----- Mortgage Quote Area CSS -----*/
.mortgage-quote-area {
    .row {
        background-color: $black-color;
        border-radius: 10px;
    }
}
.mortgage-quote-content {
    padding: 70px 50px 80px;

    h3 {
        font-weight: 700;
        font-size: 40px;
        margin-bottom: 15px;
        color: $white-color;
    }
    p {
        font-size: 15px;
        margin-bottom: 25px;
        color: $white-color;
    }
    .quote-btn {
        display: inline-block;
        padding: 15px 30px;
        border-radius: 5px;
        background: $main-color;
        color: $white-color;
        transition: $transition;

        &:hover {
            background: $white-color;
            color: $main-color;
        }
    }
}
.mortgage-quote-image {
    // background-image: url(assets/img/more-home/mortgage-quote.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    border-radius: 0px 10px 10px 0px;
}
.mortgage-quote-area-with-full-width {
    overflow: hidden;
    background-color: $black-color;
    position: relative;
    z-index: 1;

    &.with-main-color {
        background-color: $main-color;

        .mortgage-quote-content {
            .quote-btn {
                background: $black-color;
                color: $white-color;

                &:hover {
                    background-color: $white-color;
                    color: $main-color;
                }
            }
        }
    }
    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        };
    }
    .mortgage-quote-content {
        padding: 70px 50px 80px 0;
        max-width: 642px;
        margin-left: auto;

        h3 {
            margin-bottom: 18px;
        }
    }
    .mortgage-quote-image {
        border-radius: 0;
        // background-image: url(assets/img/more-home/mortgage-quote-2.jpg);
    }
}
.mortgage-quote-shape {
    position: absolute;
    bottom: 15px;
    left: 45%;
    transform: translateX(-45%);
    max-width: 150px;
    z-index: -1;
}

/*----- Why Choose Us Area CSS -----*/
.why-choose-us-area {
    position: relative;
    z-index: 1;
    border-top: 1px solid #f9f9f9;

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 508px;
        left: 0;
        top: 0;
        right: 0;
        // background-image: url(assets/img/more-home/choose/choose-bg.jpg);
        z-index: -1;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
}
.why-choose-us-card {
    .choose-image {
        overflow: hidden;
        border-radius: 10px 10px 0px 0px;

        img {
            border-radius: 10px 10px 0px 0px;
            transition: $transition;
        }
    }
    .choose-content {
        background: #F5F7FB;
        border-radius: 0px 0px 10px 10px;
        padding: 30px 25px;
        transition: $transition;

        h3 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 15px;
            line-height: 1.5;
            transition: $transition;
        }
        p {
            font-size: 15px;
            margin-bottom: 20px;
            transition: $transition;
        }
        .choose-btn {
            display: inline-block;
            padding: 15px 30px;
            border-radius: 5px;
            background: $main-color;
            color: $white-color;
            transition: $transition;
        }
    }
    &:hover {
        .choose-image {
            img {
                transform: scale(1.2);
            }
        }
        .choose-content {
            background-color: $main-color;

            h3 {
                color: $white-color;
            }
            p {
                color: $white-color;
            }
            .choose-btn {
                background: $white-color;
                color: $main-color;
            }
        }
    }
}
.why-choose-us-slider {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 10px;
                    height: 10px;
                    background-color: #DDDDDD;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;
                    margin: 0 5px;
                }
                &:hover, &.active {
                    span {
                        background: $main-color;
                        transform: scale(1.2);
                    }
                }
            }
        }
        .owl-nav.disabled+.owl-dots {
            margin-top: 30px;
        }
        .owl-nav {
            margin-top: 0;
        }
    }
}
.why-choose-us-area-without-bg {
    .main-section-title {
        max-width: 650px;
        margin: 0 auto 45px;
    }
}
.why-choose-us-item {
    background-color: #F5F7FB;
    padding: 35px;
    margin-bottom: 30px;
    transition: $transition;

    &.with-border {
        background-color: transparent;
        border: 1px solid #F5F7FB;
    }
    .choose-image {
        margin-bottom: 25px;
    }
    .choose-content {
        h3 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 0;
            line-height: 1.5;
            transition: $transition;
        }
        .list {
            padding: 0;
            margin: {
                top: 20px;
                bottom: 25px;
            };
            li {
                list-style-type: none;
                color: #767676;
                margin-bottom: 15px;
                position: relative;
                padding-left: 22px;
                transition: $transition;

                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    color: $main-color;
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: $transition;
                }
            }
        }
        .choose-btn {
            display: inline-block;
            padding: 15px 30px;
            border-radius: 5px;
            background: $main-color;
            color: $white-color;
            transition: $transition;
        }
    }
    &:hover {
        background-color: $black-color;
        transform: translateY(-5px);

        .choose-content {
            h3 {
                color: $white-color;
            }
            .list {
                li {
                    color: $white-color;

                    i {
                        color: $white-color;
                    }
                }
            }
        }
    }
}

/*----- Find Loan Officer Area CSS -----*/
.find-loan-officer-area {
    // background-image: url(assets/img/more-home/loan-officer-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $black-color;
        z-index: -1;
        opacity: .77;
    }
}
.find-loan-officer-form {
    background-color: $white-color;
    box-shadow: 0px 5px 50px rgba(13, 24, 32, 0.05);
    border-radius: 15px;
    padding: 50px 65px;

    h3 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0;
    }
    .loan-check-box {
        margin: {
            top: 25px;
            bottom: 25px;
        };
        .form-check {
            display: inline-block;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
            .form-check-label {
                color: $black-color;
            }
            .form-check-input {
                font-size: 15px;

                &:checked {
                    background-color: $main-color;
                    border-color: $main-color;
                }
                &:focus {
                    box-shadow: unset;
                }
            }
        }
    }
    form {
        .form-group {
            margin-bottom: 20px;

            label {
                display: block;
                margin-bottom: 10px;
                font-weight: 500; 
                color: $paragraph-color;
                font-size: 14px;
            }
            .form-control {
                display: block;
                height: 55px;
                font-weight: 400;
                background: #F8FAFB;
                border: 1px solid #E3E9ED;
                border-radius: 10px;
                outline: 0;
                padding: 15px 20px;
                color: $paragraph-color;
                
                &::placeholder {
                    color: $paragraph-color;
                    transition: $transition;
                }
                &:focus {
                    box-shadow: unset;

                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            .nice-select {
                float: unset;
                height: 55px !important;
                line-height: 55px;
                color: $paragraph-color;
                background: #F8FAFB;
                border: 1px solid #E3E9ED;
                font-size: 14px;
                font-weight: 500;
                border-radius: 10px;
                padding: {
                    top: 0;
                    bottom: 0;
                    left: 20px;
                    right: 35px;
                };
                .list {
                    background: $main-gradient-color;
                    border-radius: 10px;
                    margin-top: 0;
                    width: 100%;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    
                    .option {
                        transition: $transition;
                        padding: {
                            left: 20px;
                            right: 20px;
                        };
                        color: $white-color;
                        font-weight: 500;
                        &:hover {
                            background-color: $main-color !important;
                            color: $white-color;
                        }
                        &.selected {
                            background-color: transparent;
                            font-weight: 400;
                        }
                    }
                }
                &:after {
                    right: 20px;
                    width: 8px;
                    height: 8px;
                    margin-top: -5.5px;
                }
            }
        }
        .loan-button {
            text-align: center;
            margin-top: 30px;

            .default-btn {
                display: inline-block;
                padding: 15px 30px;
                background-color: $main-color;
                color: $white-color;
                width: 100%;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;

                &:hover {
                    background-color: $black-color;
                }
            }
        }
    }
}
.find-loan-officer-image {
    padding-left: 50px;
    position: relative;
    z-index: 1;
    padding: {
        top: 15px;
        bottom: 15px;
        right: 15px;
    };
    img {
        border-radius: 15px;
    }
    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 312px;
        background-color: transparent;
        right: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 10px;
        border: 1px solid $main-color;
    }
    .find-loan-popup {
        position: absolute;
        right: 0;
        bottom: 35px;
        background-color: $main-color;
        border-radius: 10px 0px 0px 10px;
        padding: 15px 45px;
        transition: $transition;

        .video-btn {
            i {
                font-size: 50px;
                color: $white-color;
            }
        }
        &:hover {
            background-color: $black-color;
        }
    }
}

/*----- Staff Area CSS -----*/
.staff-card {
    margin-bottom: 30px;

    .staff-image {
        transition: $transition;
        position: relative;
        z-index: 1;
        padding: {
            top: 0;
            left: 0;
        };
        img {
            border-radius: 15px;
        }
        &::before {
            position: absolute;
            content: "";
            height: 340px;
            width: 270px;
            background-color: transparent;
            left: 0;
            top: 0;
            z-index: -1;
            border-radius: 10px;
            border: 1px solid $main-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
        }
        .social-link {
            position: absolute;
            left: 0;
            bottom: 0;
            border-radius: 0px 10px 10px 0px;
            background-color: $main-color;
            padding: 25px;
            transition: $transition;
            opacity: 0;
            visibility: hidden;
            margin-bottom: 0;

            li {
                display: inline-block;
                margin-right: 5px;

                &:last-child {
                    margin-right: 0;
                }
                a {
                    i {
                        display: inline-block;
                        height: 30px;
                        width: 30px;
                        line-height: 30px;
                        background-color: $white-color;
                        color: $main-color;
                        text-align: center;
                        border-radius: 50px;
                        font-size: 15px;
                        transition: $transition;

                        &:hover {
                            background-color: $black-color;
                            color: $white-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
        }
    }
    .staff-content {
        text-align: center;
        margin-top: 30px;

        h3 {
            color: $black-color;
            font-weight: bold;
            font-size: 22px;
            margin-bottom: 0;
        }
        span {
            color: $paragraph-color;
            display: inline-block;
            margin-top: 10px;
        }
    }
    &:hover {
        .staff-image {
            padding: {
                top: 10px;
                left: 10px;
            };
            &::before {
                opacity: 1;
                visibility: visible;
            }
            .social-link {
                opacity: 1;
                visibility: visible;
                bottom: 35px;
            }
        }
    }
    &.without-border {
        .staff-image {
            border-radius: 15px;
            overflow: hidden;

            &::before {
                display: none;
            }
            img {
                border-radius: 15px;
                transition: $transition;
            }
        }
        &:hover {
            .staff-image {
                padding: {
                    top: 0;
                    left: 0;
                };
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    &.without-border-radius {
        .staff-image {
            border-radius: 0;
            text-align: center;

            img {
                border-radius: 0;
            }
            .social-link {
                left: 0;
                right: 0;
                border-radius: 0;
                max-width: 220px;
                margin: auto;
            }
        }
    }
}
.staff-slider {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 10px;
                    height: 10px;
                    background-color: #DDDDDD;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;
                    margin: 0 5px;
                }
                &:hover, &.active {
                    span {
                        background: $main-color;
                        transform: scale(1.2);
                    }
                }
            }
        }
        .owl-nav.disabled+.owl-dots {
            margin-top: 0;
        }
        .owl-nav {
            margin-top: 0;
        }
    }
}

/*----- Need Help FAQ Area CSS -----*/
.need-help-faq-area {
    overflow: hidden;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        };
    }
}
.need-help-faq-content {
    max-width: 640px;
    margin-left: auto;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 0;
        font-weight: bold;
    }
    .need-help-faq-accordion {
        margin-top: 30px;

        .accordion-item {
            margin-bottom: 30px;
            transition: $transition;
            border-radius: 5px;
            border: none;
            
            .accordion-button {
                color: $black-color;
                position: relative;
                text-align: start;
                box-shadow: unset;
                margin-bottom: 0;
                display: block;
                border: none;
                width: 100%;
                font-size: 18px;
                font-weight: bold;
                padding: 0 45px 0 0;
                transition: $transition;
                background-color: transparent;
                
                &::before {
                    position: absolute;
                    right: 0;
                    top: 3.5px;
                    content: '-';
                    font-size: 15px;
                    color: $transition;
                    display: inline-block;
                    height: 20px;
                    width: 20px;
                    line-height: 20px;
                    text-align: center;
                    background-color: $main-color;
                    color: $white-color;
                    border-radius: 50px;
                }
                &.collapsed {
                    &::before {
                        content: '+';
                        color: $white-color;
                    }
                }
            }
            .accordion-body {
                padding: 20px 0 0 0;
                transition: $transition;
                border: none;
    
                p {
                    color: $paragraph-color;
                    font-size: 15px;
                }
            }
            .accordion-collapse {
                border: none;
            }
            &:last-child {
                margin-bottom: 0;

                .accordion-body {
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
.need-help-faq-image {
    margin-left: 50px;
    position: relative;
    z-index: 1;
    padding: {
        top: 15px;
        bottom: 15px;
        left: 15px;
    };
    img {
        border-radius: 10px 0px 0px 10px;
        border: 1px solid #eeeeee;
    }
    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 312px;
        background-color: transparent;
        left: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 10px;
        border: 1px solid $main-color;
    }
    .help-box {
        position: absolute;
        left: 0;
        bottom: 35px;
        background-color: $main-color;
        border-radius: 0 10px 10px 0;
        padding: 30px;
        text-align: center;

        h3 {
            font-weight: bold;
            color: $white-color;
            font-size: 45px;
            margin-bottom: 12px;
            line-height: 1;
        }
        span {
            color: $white-color;
        }
    }
}

/*----- Testimonials Style Area CSS -----*/
.testimonials-style-area  {
    background-color:  #F5F7FB;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &.bg-ffffff {
        background-color: $white-color;
    }
}
.testimonials-style-content {
    padding-left: 30px;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        font-weight: bold;
    }
    &.with-border-color {
        border: 1px solid #DDDDDD;
        padding: 35px;
        border-radius: 5px;
    }
}
.testimonials-style-card {
    .info {
        position: relative;
        padding: {
            left: 90px;
            top: 30px;
            bottom: 30px;
        };
        i {
            display: inline-block;
            height: 70px;
            width: 70px;
            line-height: 70px;
            text-align: center;
            font-size: 45px;
            text-align: center;
            border-radius: 50px;
            background-color: $main-color;
            color: $white-color;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        h4 {
            font-size: 18px;
            margin-bottom: 10px;
            font-weight: bold;
        }
        span {
            color: $paragraph-color;
            font-size: 14px;
        }
    }
    p {
        margin-bottom: 0;
    }
    .star-list {
        padding: 0;
        margin: {
            top: 25px;
            bottom: 0;
        };
        li {
            margin-right: 5px;
            display: inline-block;

            &:last-child {
                margin-right: 0;
            }
            i {
                color: #FFC700;
                font-size: 18px;
            }
        }
    }
}
.testimonials-style-slider {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 10px;
                    height: 10px;
                    background-color: #DDDDDD;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;
                    margin: 0 5px;
                }
                &:hover, &.active {
                    span {
                        background: $main-color;
                        transform: scale(1.2);
                    }
                }
            }
        }
        .owl-nav.disabled+.owl-dots {
            margin-top: 30px;
            text-align: start;
        }
        .owl-nav {
            margin-top: 0;
        }
    }
}
.testimonials-style-area-with-bg {
    // background-image: url(assets/img/more-home/testimonials/testimonials-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    .testimonials-style-content {
        padding-left: 0;
        background-color: $white-color;
        padding: 50px;
        max-width: 750px;

        .info {
            padding-top: 40px;
            padding-bottom: 25px;
        }
    }
    &.bg-style-two {
        // background-image: url(assets/img/more-home/testimonials/testimonials-bg-2.jpg);

        .testimonials-style-content {
            padding-left: 0;
            background-color: transparent;
            padding: 0;
            max-width: 750px;
        }
    }
}
.testimonials-gradient-shape {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

/*----- Let’s Contact Area CSS -----*/
.let-contact-area {
    background-color: $black-color;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        };
    }
    &.with-main-color {
        background-color: $main-color;

        .let-contact-form {
            .sub-title {
                color: $white-color;
            }
            form {
                .let-contact-btn {
                    .main-default-btn {
                        background-color: $black-color;
                        color: $white-color;

                        &:hover {
                            background-color: $white-color;
                            color: $main-color;
                        }
                    }
                }
                .text-danger {
                    color: $white-color !important;
                }
            }
        }
    }
}
.let-contact-form {
    max-width: 640px;
    margin-left: auto;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
        position: relative;
        left: 8px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 0;
        font-weight: bold;
        color: $white-color;
        position: relative;
        left: 8px;
    }
    form {
        margin-top: 25px;

        .form-group {
            margin-bottom: 20px;

            label {
                display: block;
                margin-bottom: 10px;
                font-weight: 500; 
                color: $white-color;
                font-size: 14px;
            }
            .form-control {
                display: block;
                height: 55px;
                font-weight: 400;
                background: #F8FAFB;
                border: 1px solid #E3E9ED;
                border-radius: 10px;
                outline: 0;
                padding: 15px 20px;
                color: #555555;
                
                &::placeholder {
                    color: #555555;
                    transition: $transition;
                }
                &:focus {
                    box-shadow: unset;

                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            textarea {
                &.form-control {
                    min-height: 120px;
                }
            }
            .list-unstyled {
                li {
                    font-size: 12px;
                    margin-top: 10px;
                    color: red;
                }
            } 
        }
        .let-contact-btn {
            .main-default-btn {
                display: inline-block;
                padding: 15px 30px;
                background-color: $main-color;
                color: $white-color;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;

                &:hover {
                    background-color: $white-color;
                    color: $main-color;
                }
            }
        }
        .text-danger {
            color: $main-color !important;
            font-size: 15px;
            font-weight: 500;
            margin: {
                top: 10px;
                bottom: 0;
            };
        } 
    }
}
.let-contact-map {
    padding-left: 50px;

    iframe {
        width: 100%;
        height: 800px;
        margin-bottom: -10px;
    }
}
.let-contact-area-with-bg {
    // background-image: url(assets/img/more-home/let-contact-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $black-color;
        z-index: -1;
        opacity: .77;
    }
    .main-section-title {
        h2 {
            color: $white-color;
        }
    }
    .let-contact-form {
        max-width: 100%;

        form {
            margin-top: 0;

            .form-group {
                margin-bottom: 20px;
                
                .form-control {
                    height: 60px;
                    color: #555555;
                    font-size: 15px;
                    border-radius: 5px;
                    
                    &::placeholder {
                        color: $paragraph-color;
                        transition: $transition;
                    }
                    &:focus {
                        box-shadow: unset;
    
                        &::placeholder {
                            color: transparent;
                        }
                    }
                }
                textarea {
                    &.form-control {
                        min-height: 172px;
                    }
                }
            }
            .let-contact-btn {
                .main-default-btn {
                    width: 100%;
                    border-radius: 5px;
                }
            }
        }
    }
}
.let-contact-area-with-bg-image {
    // background-image: url(assets/img/more-home/let-contact-bg-2.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    .let-contact-form {
        margin-left: 0;
        margin-right: auto;

        h3 {
            color: $black-color;
        }
        form {
            .form-group {
                .form-control {
                    font-size: 14px;
                }
                label {
                    color: $black-color;
                }
            }
        }
    }
}
.let-contact-shape {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 30%;
}
.let-contact-form-with-gray-color {
    margin-top: 50px;
    position: relative;
    z-index: 1;

    .main-section-title {
        .sub-title {
            color: $white-color;
        }
        h2 {
            color: $white-color;
        }
    }
    form {
        background: #F5F7FB; 
        padding: 50px;
        max-width: 880px;
        margin: auto;
        border-radius: 5px;

        .form-group {
            margin-bottom: 20px;

            label {
                display: block;
                margin-bottom: 10px;
                font-weight: 500; 
                color: $black-color;
                font-size: 14px;
            }
            .form-control {
                display: block;
                height: 55px;
                font-weight: 400;
                background: $white-color;
                border: 1px solid #E3E9ED;
                border-radius: 10px;
                outline: 0;
                padding: 15px 20px;
                color: #555555;
                
                &::placeholder {
                    color: #555555;
                    transition: $transition;
                }
                &:focus {
                    box-shadow: unset;

                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            textarea {
                &.form-control {
                    min-height: 120px;
                }
            }
            .list-unstyled {
                li {
                    font-size: 12px;
                    margin-top: 10px;
                    color: red;
                }
            } 
        }
        .let-contact-btn {
            .main-default-btn {
                display: inline-block;
                padding: 15px 30px;
                background-color: $black-color;
                color: $white-color;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;

                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
        .text-danger {
            color: $main-color !important;
            font-size: 15px;
            font-weight: 500;
            margin: {
                top: 10px;
                bottom: 0;
            };
        } 
    }
    .let-contact-shape {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }
}

/*----- Blog Style Area CSS -----*/
.blog-style-left-card {
    .blog-image {
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        .tag {
            display: inline-block;
            padding: 5px 15px;
            border-radius: 5px;
            background-color: $main-color;
            color: $white-color;
            position: absolute;
            left: 20px;
            top: 20px;
            font-size: 14px;
        }
        img {
            border-radius: 10px;
            transition: $transition;
        }
    }
    .blog-content {
        margin-top: 25px;

        .post-meta {
            padding: 0;
            margin-bottom: 25px;

            li {
                color: $paragraph-color;
                font-size: 15px;
                list-style-type: none;
                display: inline-block;
                margin-right: 15px;
                position: relative;
                padding-left: 22px;

                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
                .post-author {
                    img {
                        width: 35px;
                        height: 35px;
                        margin-right: 10px;
                    }
                    span {
                        font-weight: 400;
                        font-size: 15px;
                        color: $paragraph-color;
                    }
                }
                i {
                    position: absolute;
                    left: 0;
                    top: 2.8px;
                    color: $main-color;
                }
            }
        }
        h3 {
            font-size: 24px;
            margin-bottom: 15px;
            font-weight: bold;

            a {
                color: $black-color;
            }
        }
        p {
            margin-bottom: 0;
        }
    }
    &:hover {
        .blog-image {
            img {
                transform: scale(1.2);
            }
        }
    }
}
.blog-style-right-card {
    padding-left: 45px;

    .card-box {
        margin-bottom: 40px;
        padding-bottom: 30px;
        border-bottom: 1px solid #E3E9ED;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
        .blog-content {
            .tag {
                display: inline-block;
                padding: 5px 15px;
                border-radius: 5px;
                background-color: $main-color;
                color: $white-color;
                font-size: 14px;
                margin-bottom: 22px;
                transition: $transition;

                &:hover {
                    background-color: $black-color;
                }
            }
            .post-meta {
                padding: 0;
                margin-bottom: 15px;
    
                li {
                    color: $paragraph-color;
                    font-size: 15px;
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 15px;
                    position: relative;
                    padding-left: 22px;
                    
                    &:last-child {
                        margin-right: 0;
                    }
                    i {
                        position: absolute;
                        left: 0;
                        top: 2.8px;
                        color: $main-color;
                    }
                }
            }
            h3 {
                font-size: 24px;
                margin-bottom: 12px;
                font-weight: bold;
                line-height: 1.5;
    
                a {
                    color: $black-color;
                }
            }
            p {
                margin-bottom: 0;
            }
        }
    }
}
.blog-style-card {
    margin-bottom: 30px;
    background-color: #F5F7FB;
    padding: 30px;
    transition: $transition;

    .tag {
        display: inline-block;
        padding: 5px 15px;
        border-radius: 5px;
        background-color: $main-color;
        color: $white-color;
        margin-bottom: 28px;
    }
    .post-meta {
        padding: 0;
        margin-bottom: 20px;

        li {
            color: $paragraph-color;
            font-size: 14px;
            list-style-type: none;
            display: inline-block;
            margin-right: 15px;
            position: relative;
            padding-left: 22px;
            transition: $transition;

            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            .post-author {
                img {
                    width: 35px;
                    height: 35px;
                    margin-right: 5px;
                    display: inline-block;
                }
                span {
                    font-weight: 400;
                    font-size: 14px;
                    color: $paragraph-color;
                    transition: $transition;
                }
            }
            i {
                position: absolute;
                left: 0;
                top: 0;
                color: $main-color;
                transition: $transition;
            }
        }
    }
    h3 {
        font-size: 22px;
        font-weight: bold;
        line-height: 1.5;
        margin-bottom: 15px;

        a {
            color: $black-color;
            transition: $transition;
        }
    }
    p {
        margin-bottom: 0;
        transition: $transition;
        font-size: 15px;
    }
    &:hover {
        background-color: $black-color;

        .post-meta {
            li {
                color: $white-color;

                .post-author {
                    span {
                        color: $white-color;
                    }
                }
                i {
                    color: $main-color;
                }
            }
        }
        h3 {
            a {
                color: $white-color;
            }
        }
        p {
            color: $white-color;
        }
    }
}
.blog-style-slider {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 10px;
                    height: 10px;
                    background-color: #DDDDDD;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;
                    margin: 0 5px;
                }
                &:hover, &.active {
                    span {
                        background: $main-color;
                        transform: scale(1.2);
                    }
                }
            }
        }
        .owl-nav.disabled+.owl-dots {
            margin-top: 0;
        }
        .owl-nav {
            margin-top: 0;
        }
    }
}

/*----- Subscribe Style Area CSS -----*/
.subscribe-style-area {
    // background-image: url(assets/img/more-home/subscribe/subscribe-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &.bg-style-two {
        // background-image: url(assets/img/more-home/subscribe/subscribe-bg-2.jpg);
    }
}
.subscribe-style-wrap-content {
    h2 {
        color: $white-color;
        font-weight: bold;
        margin-bottom: 0;
        font-size: 40px;
    }
    .newsletter-form {
        position: relative;
        
        .form-control {
            display: block;
            width: 100%;
            background-color: $white-color;
            border: none;
            height: 70px;
            padding: 15px 25px;
            border-radius: 10px;
            outline: 0;
            color: $paragraph-color;
            font-size: 15px;

            &::placeholder {
                color: $paragraph-color;
                transition: $transition;
            }
            &:focus {
                box-shadow: unset;

                &::placeholder {
                    color: transparent;
                }
            }
        }
        .main-default-btn {
            background: $main-gradient-color;
            color: $white-color !important;
            padding: 20px;
            position: absolute;
            top: 4px;
            right: 4px;
        }
        #validator-newsletter {
            color: $white-color;
            font-size: 14px;
            margin-top: 10px;
        }
    }
}

/*----- Subscribe Style Area CSS -----*/
.footer-style-area {
    background: $black-color;

    .copyright-style-area {
        margin-top: 70px;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid #212e37;

        .copyright-item {
            p {
                margin-bottom: 0;
                color: #E3E9ED;
                font-size: 15px;

                a {
                    display: inline-block;
                    color: $main-color;
                    font-weight: 600;

                    &:hover {
                        letter-spacing: 1px;
                    }
                }
            }
            .payment-list {
                margin: 0;
                padding: 0;
                text-align: end;

                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-right: 8px;

                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        display: block;

                        &:hover {
                            transform: translateY(-5px);
                        }
                    }
                }
            }
        }
    }
}
.single-footer-widget {
    margin-bottom: 25px;

    .footer-logo {
        a {
            img {
                margin-bottom: 30px;
            }
        }
        p {
            color: $white-color;
            margin-bottom: 20px;
            font-size: 15px;
        }
        .footer-social {
            padding: 0;
            margin-bottom: 0;

            li {
                display: inline-block;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
                a {
                    i {
                        display: inline-block;
                        height: 35px;
                        width: 35px;
                        line-height: 35px;
                        text-align: center;
                        border-radius: 50px;
                        background: $main-color;
                        color: $white-color;
                        transition: $transition;
                        font-size: 15px;
                        
                        &:hover {
                            background-color: $white-color;
                            color: $main-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
        }
    }
    h3 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 25px;
        color: $white-color;
    }
    .quick-link {
        padding: 0 0 0 0;
        margin-bottom: 0;

        li {
            list-style-type: none;
            margin-bottom: 15px;
            position: relative;
            padding-left: 22px;
            font-size: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: $white-color;

                &:hover {
                    color: $main-color;
                }
            }
            &::before {
                position: absolute;
                content: "";
                height: 10px;
                width: 10px;
                background: $main-gradient-color;
                border-radius: 50px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .address-info {
        padding: 0;
        margin-bottom: 0;

        li {
            list-style-type: none;
            margin-bottom: 15px;
            font-size: 15px;
            color: $white-color;
            position: relative;
            padding-left: 22px;

            &:last-child {
                margin-bottom: 0;
            }
            i {
                color: $main-color;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            a {
                color: $white-color;
                font-size: 15px;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
}

/*----- Banner Buy Area CSS -----*/
.banner-buy-area {
    // background-image: url(assets/img/more-home/banner/banner-buy-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 70px;
    .container-fluid {
        padding: {
            left: 30px;
            right: 30px;
        };
    }
}
.banner-buy-content {
    background: rgba(0, 0, 0, 0.5);
    padding: 70px 80px;
    max-width: 850px;
    border-radius: 0px 0px 50px 50px;

    .sub-title {
        display: inline-block;
        margin-bottom: 18px;
        color: $white-color;
    }
    h1 {
        font-size: 85px;
        margin-bottom: 15px;
        font-weight: bold;
        color: $white-color;
    }
    p {
        margin-bottom: 0;
        color: $white-color;
        font-size: 15px;
    }
    .banner-buy-btn {
        padding: 0;
        margin: {
            top: 30px;
            bottom: 0;
        };
        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 18px;

            &:last-child {
                margin-right: 0;
            }
            .main-default-btn {
                color: $white-color;
            }
            .main-optional-btn {
                display: inline-block;
                padding: 15px 30px;
                background-color: $white-color;
                color: $main-color;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;
            
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.banner-buy-info-card {
    position: relative;
    margin-top: 50px;
    padding-left: 82px;

    .icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        i {
            display: inline-block;
            height: 65px;
            width: 65px;
            line-height: 65px;
            background-color: $main-color;
            color: $white-color;
            text-align: center;
            border-radius: 50px;
            font-size: 35px;
            transition: $transition;
        }
    }
    p {
        margin-bottom: 0;

        span {
            display: block;
            color: $white-color;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: $white-color;
            }
        }
    }
    &:hover {
        .icon {
            i {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}

/*----- Offer Style Area CSS -----*/
.offer-style-card {
    position: relative;
    transition: $transition;
    margin-bottom: 30px;

    &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        width: 100%;
        height: 90%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 61.46%, rgba(255, 255, 255, 0.9) 100%);
        bottom: 0;
    }
    .offer-content {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0px 30px 35px 30px;

        h3 {
            font-size: 20px;
            color: $black-color;
            margin-bottom: 12px;
            font-weight: bold;
            line-height: 1.5;
        }
        a {
            font-weight: 500;
            color: $black-color;
            font-size: 15px;
            transition: $transition;
        }
    }
    &:hover {
        transform: translateY(10px);

        .offer-content {
            a {
                color: $main-color;
            }
        }
    }
    &.with-black-color {
        &::before {
            background: linear-gradient(180deg, rgba(13, 24, 32, 0) 0%, #000000 100%, rgba(0, 0, 0, 0.7) 100%);
        }
        &::after {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            width: 100%;
            height: 90%;
            background: linear-gradient(180deg, rgba(0, 193, 148, 0) 0%, rgba(0, 173, 133, 0.682292) 61.98%, #00A47E 100%);
            bottom: 0;
            transition: $transition;
            opacity: 0;
            visibility: hidden;
        }
        .offer-content {
            z-index: 1;

            h3 {
                color: $white-color;
            transition: $transition;
            }
            a {
                color: $white-color;
                transition: $transition;
            }
        }
        &:hover {
            &::after {
                opacity: 1;
                visibility: visible;
            }
            .offer-content {
                h3 {
                    color: $white-color;
                }
                a {
                    color: $white-color;
                }
            }
        }
    }
}
.offer-style-slider {
    &.owl-theme {
        .owl-dots {
            .owl-dot {
                span {
                    width: 10px;
                    height: 10px;
                    background-color: #DDDDDD;
                    transition: $transition;
                    border-radius: 50%;
                    position: relative;
                    margin-top: 0;
                    margin-bottom: 0;
                    margin: 0 5px;
                }
                &:hover, &.active {
                    span {
                        background: $main-color;
                        transform: scale(1.2);
                    }
                }
            }
        }
        .owl-nav.disabled+.owl-dots {
            margin-top: 0;
        }
        .owl-nav {
            margin-top: 0;
        }
    }
}
.offer-style-area-with-full-width {
    position: relative;
    z-index: 1;
    overflow: hidden;

    .container-fluid {
        left: calc((100% - 1320px)/2);
        position: relative;
    }
    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 950px;
        background: #F5F7FB;
        border-radius: 0px 100px 0px 0px;
        z-index: -1;
    }
}
.offer-style-section-content {
    .sub-title {
        display: inline-block;
        margin-bottom: 15px;
        color: $main-color;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        font-weight: bold;
    }
    p {
        margin-bottom: 0;
        font-size: 15px;
    }
}
.offer-style-item {
    margin-bottom: 30px;

    .offer-image {
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            width: 100%;
            height: 90%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 61.46%, rgba(0, 0, 0, 0.8) 100%);
            bottom: 0;
        }
    }
    .offer-content {
        padding: 30px;
        border: 1px solid #EBE9EE;
        background-color: $white-color;
        margin-top: 10px;
        transition: $transition;

        h3 {
            font-size: 18px;
            color: $black-color;
            margin-bottom: 12px;
            font-weight: bold;
            line-height: 1.6;
            transition: $transition;
        }
        a {
            font-weight: 500;
            color: $black-color;
            font-size: 14px;
            transition: $transition;
            transition: $transition;
        }
    }
    &:hover {
        .offer-content {
            border: 1px solid $main-color;
            background-color: $main-color;
    
            h3 {
                color: $white-color;
            }
            a {
                color: $white-color;
            }
        }
    }
}

/*----- Get Introduced Area CSS -----*/
.get-introduced-area {
    background-color: $main-color;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        };
    }
    &::before {
        position: absolute;
        content: "";
        width: 250px;
        height: 100%;
        background-color: $white-color;
        right: 0;
        top: 0;
        z-index: -1;
    }
    &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 90px;
        background-color: $white-color;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
}
.get-introduced-content {
    max-width: 640px;
    margin-left: auto;

    .sub-title {
        display: inline-block;
        margin-bottom: 18px;
        color: $white-color;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        font-weight: bold;
        color: $white-color;
    }
    p {
        margin-bottom: 0;
        color: $white-color;
        font-size: 15px;
    }
    .introduced-inner-box {
        background-color: #00ad85;
        padding: 35px 20px 35px 85px;
        border-radius: 5px;
        position: relative;
        margin-top: 30px;

        .icon {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);

            i {
                display: inline-block;
                height: 50px;
                width: 50px;
                line-height: 50px;
                background-color: $main-color;
                color: $white-color;
                text-align: center;
                font-size: 25px;
                border-radius: 64% 36% 35% 65% / 65% 44% 56% 35%;
            }
        }
        span {
            color: $white-color;
            font-size: 15px;
        }
    }
    .introduced-counter-item {
        margin-top: 80px;

        .counter-card {
            position: relative;
            margin-top: 25px;
    
            h3 {
                font-weight: 700;
                font-size: 55px;
                color: $main-color;
                margin-bottom: 0;
                position: absolute;
                top: 0;
                left: 0;
            }
            p {
                margin-bottom: 0;
                font-size: 15px;
                color: $black-color;
                padding-left: 85px;
                padding-top: 10px;
                font-weight: 500;
            }
        }
    }
}
.get-introduced-image {
    // background-image: url(assets/img/more-home/get-introduced.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    margin-left: 35px;
    border-radius: 10px 0 0 10px;
}

/*----- Video Overview Area CSS -----*/
.video-overview-image {
    position: relative;
    z-index: 1;
    text-align: center;

    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: $transition;
        background: $main-gradient-color;
        z-index: 1;
        margin: 50px;
        opacity: .77;
    }
    .video-btn {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        i {
            width: 100px;
            height: 100px;
            line-height: 100px;
            display: inline-block;
            text-align: center;
            border-radius: 50%;
            background: $white-color;
            color: $main-color;
            font-size: 70px;
            margin-right: 10px;
            transition: $transition;
        }
        &:hover {
            i {
                background-color: $main-color;
                color: $white-color;
            }
        }
        span {
            color: $black-color;
            font-weight: 500;
            font-size: 14px;
            position: relative;
            top: -5px;
        }
    }
}
.video-overview-content {
    padding-left: 45px;

    .overview-inner-box {
        margin-bottom: 35px;
        position: relative;
        padding-left: 100px;

        &:last-child {
            margin-bottom: 0;
        }
        .icon {
            position: absolute;
            left: 0;
            top: 1.5px;

            i {
                display: inline-block;
                width: 80px;
                height: 75px;
                line-height: 75px;
                border-radius: 10px;
                background: #e5f9f4;
                color: $main-color;
                font-size: 45px;
                text-align: center;
                transition: $transition;
            }
        }
        h3 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 12px;
        }
        p {
            margin-bottom: 10px;
            font-size: 15px;
        }
        a {
            font-weight: 500;
            color: $black-color;
            font-size: 15px;
            transition: $transition;
        }
        &:hover {
            .icon {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}

/*----- Get Address Area CSS -----*/
.get-address-content {
    .sub-title {
        display: inline-block;
        margin-bottom: 18px;
        color: $main-color;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 15px;
        font-weight: bold;
    }
    .inner-box {
        margin-top: 30px;
        position: relative;
        padding-left: 75px;
        
        &::before {
            width: 1px;
            height: 140%;
            background-color: $main-color;
            content: '';
            z-index: -1;
            position: absolute;
            left: 28px;
            top: 0;
        }
        &:last-child {
            &::before {
                display: none;
            }
        }
        .number {
            display: inline-block;
            height: 50px;
            width: 50px;
            line-height: 50px;
            background-color: $main-color;
            color: $white-color;
            text-align: center;
            font-size: 25px;
            border-radius: 64% 36% 35% 65% / 65% 44% 56% 35%;
            font-weight: bold;
            font-style: italic;
            position: absolute;
            left: 0;
            top: 0;
        }
        h4 {
            font-size: 22px;
            margin-bottom: 12px;
            font-weight: bold;
        }
        p {
            color: $paragraph-color;
        }
    }
}
.get-address-form {
    background-color: $main-color;
    border-radius: 15px;
    padding: 50px 65px;
    margin-left: 35px;

    h3 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0;
        color: $white-color;
    }
    .loan-check-box {
        margin: {
            top: 25px;
            bottom: 25px;
        };
        .form-check {
            display: inline-block;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
            .form-check-label {
                color: $white-color;
            }
            .form-check-input {
                font-size: 15px;

                &:checked {
                    background-color: $black-color;
                    border-color: $black-color;
                }
                &:focus {
                    box-shadow: unset;
                }
            }
        }
    }
    form {
        .form-group {
            margin-bottom: 20px;

            label {
                display: block;
                margin-bottom: 10px;
                font-weight: 500; 
                color: $white-color;
                font-size: 14px;
            }
            .form-control {
                display: block;
                height: 55px;
                font-weight: 400;
                background: #F8FAFB;
                border: 1px solid #E3E9ED;
                border-radius: 10px;
                outline: 0;
                padding: 15px 20px;
                color: $paragraph-color;
                
                &::placeholder {
                    color: $paragraph-color;
                    transition: $transition;
                }
                &:focus {
                    box-shadow: unset;

                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            .nice-select {
                float: unset;
                height: 55px !important;
                line-height: 55px;
                color: $paragraph-color;
                background: #F8FAFB;
                border: 1px solid #E3E9ED;
                font-size: 14px;
                font-weight: 500;
                border-radius: 10px;
                padding: {
                    top: 0;
                    bottom: 0;
                    left: 20px;
                    right: 35px;
                };
                .list {
                    background: $main-gradient-color;
                    border-radius: 10px;
                    margin-top: 0;
                    width: 100%;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    
                    .option {
                        transition: $transition;
                        padding: {
                            left: 20px;
                            right: 20px;
                        };
                        color: $white-color;
                        font-weight: 500;
                        &:hover {
                            background-color: $main-color !important;
                            color: $white-color;
                        }
                        &.selected {
                            background-color: transparent;
                            font-weight: 400;
                        }
                    }
                }
                &:after {
                    right: 20px;
                    width: 8px;
                    height: 8px;
                    margin-top: -5.5px;
                }
            }
        }
        .loan-button {
            text-align: center;
            margin-top: 30px;

            .default-btn {
                display: inline-block;
                padding: 15px 30px;
                background-color: $black-color;
                color: $white-color;
                width: 100%;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;

                &:hover {
                    background-color: $white-color;
                    color: $main-color;
                }
            }
        }
    }
}

/*----- FAQ Style Area CSS -----*/
.faq-style-accordion {
    max-width: 870px;
    margin: auto;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 35px;
        font-weight: bold;
    }
    .accordion-item {
        margin-bottom: 20px;
        transition: $transition;
        border-radius: 0;
        border: none;
        
        .accordion-button {
            color: $black-color;
            position: relative;
            text-align: start;
            box-shadow: unset;
            margin-bottom: 0;
            display: block;
            border: none;
            width: 100%;
            font-size: 18px;
            font-weight: 600;
            padding: 25px 65px 25px 25px;
            transition: $transition;
            background-color: $main-color;
            color: $white-color;
            border-radius: 0;
            
            &::before {
                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-50%);
                content: '-';
                font-size: 15px;
                color: $transition;
                display: inline-block;
                height: 20px;
                width: 20px;
                line-height: 20px;
                text-align: center;
                background-color: $white-color;
                color: $main-color;
                border-radius: 50px;
            }
            &.collapsed {
                background-color: #E5F9F4;
                color: $black-color;
                
                &::before {
                    content: '+';
                    color: $white-color;
                    background-color: $main-color;
                }
            }
        }
        .accordion-body {
            padding: 20px 0 25px 20px;
            transition: $transition;
            border: none;

            p {
                color: $paragraph-color;
                font-size: 15px;
                margin-bottom: 0;
            }
        }
        .accordion-collapse {
            border: none;
        }
        &:last-child {
            margin-bottom: 0;

            .accordion-body {
                padding-bottom: 0;
            }
        }
    }
}
.faq-style-area-with-full-width {
    overflow: hidden;

    .container-fluid {
        padding-right: var(--bs-gutter-x,.75rem);
        padding-left: var(--bs-gutter-x,.75rem);
    }
    .faq-style-accordion {
        max-width: 640px;
        margin: 0 auto 0 0;
    }
    &.with-black-color {
        background-color: $black-color;

        .faq-style-accordion {
            h3 {
                color: $white-color;
            }
            .accordion-item {
                background-color: transparent;
    
                .accordion-body {
                    padding-right: 15px;
    
                    p {
                        color: $white-color;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
.faq-style-area-with-full-width-wrap {
    background-color: $black-color;
    overflow: hidden;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        };
    }
    .faq-style-accordion {
        max-width: 640px;
        margin: 0 0 0 auto;
        padding: {
            top: 100px;
            bottom: 100px;
            right: 50px;
        };
        .sub-title {
            color: $main-color;
            display: inline-block;
            margin-bottom: 18px;
        }
        h3 {
            font-size: 40px;
            margin-bottom: 35px;
            font-weight: bold;
            color: $white-color;
        }
        .accordion-item {
            background-color: transparent;

            .accordion-body {
                padding-right: 15px;

                p {
                    color: $white-color;
                    font-size: 15px;
                }
            }
        }
    }
    &.bg-transparent {
        background-color: transparent;

        .faq-style-accordion {
            max-width: 640px;
            margin: 0 auto 0 0;
            padding: {
                top: 0;
                bottom: 0;
                right: 0;
                left: 50px;
            };
            .sub-title {
                color: $main-color;
            }
            h3 {
                color: $black-color;
            }
            .accordion-item {
                .accordion-body {
                    p {
                        color: $paragraph-color;
                    }
                }
            }
        }
        .faq-style-image-wrap {
            border-radius: 0px 0px 100px 0px;
        }
    }
}
.faq-style-image-wrap {
    // background-image: url(assets/img/more-home/faq-2.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;

    &.bg-2 {
        // background-image: url(assets/img/more-home/faq-4.jpg);
    }
}

/*----- Better Home Area CSS -----*/
.better-home-area {
    // background-image: url(assets/img/more-home/banner/better-home-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 120px;
    position: relative;
    z-index: 1;

    .container-fluid {
        padding: {
            left: 30px;
            right: 30px;
        };
    }
    .social-links {
        left: 30px;
        bottom: 30px;
        padding-left: 0;
        margin-bottom: 0;
        position: absolute;
        list-style-type: none;

        li {
            display: inline-block;
            margin-right: 10px;

            a {
                display: inline-block;
                height: 35px;
                width: 35px;
                line-height: 35px;
                background: #DDDDDD;
                color: $white-color;
                font-size: 20px;
                text-align: center;
                border-radius: 50px;
                transition: $transition;

                &:hover {
                    background-color: $main-color;
                    transform: translateY(-5px);
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.better-home-content {
    position: relative;
    top: -50px;

    .sub-title {
        display: inline-block;
        margin-bottom: 18px;
        color: $main-color;
    }
    h1 {
        font-size: 85px;
        margin-bottom: 15px;
        font-weight: bold;
    }
    p {
        margin-bottom: 0;
        font-size: 15px;
        max-width: 610px;
    }
    .better-home-btn {
        padding: 0;
        margin: {
            top: 30px;
            bottom: 0;
        };
        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 18px;

            &:last-child {
                margin-right: 0;
            }
            .main-default-btn {
                color: $white-color;
            }
            .main-optional-btn {
                display: inline-block;
                padding: 15px 30px;
                background-color: $black-color;
                color: $white-color;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;
            
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.better-home-image {
    position: relative;
    z-index: 1;
    text-align: center;

    .video-btn {
        position: absolute;
        left: 100px;
        right: 0;
        margin: auto;
        bottom: 0;
        display: inline-block;
        height: 130px;
        width: 130px;
        line-height: 130px;
        background-color: $black-color;
        border-radius: 64% 36% 35% 65% / 65% 44% 56% 35%;

        i {
            display: inline-block;
            height: 65px;
            width: 65px;
            line-height: 65px;
            background-color: $white-color;
            border-radius: 50px;
            font-size: 45px;
            color: $black-color;
            position: relative;
            top: 16px;
            transition: $transition;

            &:hover {
                background-color: $main-color;
                color: $white-color;
            }
        }
    }
}

/*----- Information Area CSS -----*/
.information-area {
    position: relative;
    z-index: 1;

    &.with-bg-black-color {
        background-color: #172632;

        .information-card {
            .icon {
                i {
                    background: rgba(0, 193, 149, 0.2);
                }
            }
            p {
                span {
                    color: $white-color;

                    a {
                        color: $white-color;

                        &:hover {
                            color: $main-color;
                        }
                    }
                }
            }
            &:hover {
                .icon {
                    i {
                        background: $main-color;
                    }
                }
            }
        }
    }
    &.with-bg-main-color {
        background-color: $main-color;
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100px;
            background-color: $white-color;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
        }
        .information-card {
            .icon {
                i {
                    background: #00A47E;
                }
            }
            p {
                span {
                    color: $white-color;

                    a {
                        color: $white-color;

                        &:hover {
                            color: $black-color;
                        }
                    }
                }
            }
            &:hover {
                .icon {
                    i {
                        background: $white-color;
                        color: $main-color;
                    }
                }
            }
        }
    }
}
.information-card {
    position: relative;
    margin-bottom: 30px;
    padding-left: 82px;

    .icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        i {
            display: inline-block;
            height: 65px;
            width: 65px;
            line-height: 65px;
            background-color: $main-color;
            color: $white-color;
            text-align: center;
            border-radius: 50px;
            font-size: 35px;
            transition: $transition;
        }
    }
    p {
        margin-bottom: 0;
        font-size: 15px;
        font-weight: 500;

        span {
            display: block;
            color: $black-color;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: $black-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    &:hover {
        .icon {
            i {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}
.information-shape-1 {
    position: absolute;
    left: 50px;
    bottom: 250px;
    z-index: -1;
}
.information-shape-2 {
    position: absolute;
    right: 50px;
    top: 100px;
    z-index: -1;
}

/*----- Beyond Style Area CSS -----*/
.beyond-style-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.beyond-style-list-tab {
    position: relative;

    #thumbs {
        margin-bottom: 50px;
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            content: "";
            height: 1px;
            width: 100%;
            background-color: $main-color;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
        }
        .tabs-item {
            text-align: center;

            span {
                display: inline-block;
                height: 75px;
                width: 75px;
                line-height: 75px;
                text-align: center;
                border-radius: 50%;
                font-size: 32px;
                font-weight: bold;
                border: 1px solid $main-color;
                background-color: $white-color;
                color: $black-color;
            }
        }
        .current {
            .tabs-item {
                span {
                    background-color: $main-color;
                    border: 1px solid $main-color;
                    color: $white-color;
                }
            }
        }
    }
    #beyond-style-item {
        .beyond-style-content {
            padding-right: 20px;

            h3 {
                font-size: 40px;
                font-weight: bold;
                margin-bottom: 20px;
            }
            .bold {
                color: $black-color;
                font-weight: 500;
                font-size: 15px;
            }
        }
    }
    .owl-theme {
        .owl-nav {
            [class*=owl-] {
                position: absolute;
                left: 0;
                top: 0;
                margin: 0;
                padding: 0;
                transition: $transition;
                font-size: 35px;
                display: inline-block;
                height: 75px;
                width: 75px;
                line-height: 0px;
                background-color: $white-color;
                color: $black-color;
                border: 1px solid $main-color;
                border-radius: 50px;
                z-index: 1;
                
                &.owl-next {
                    left: auto;
                    right: 0;
                }
                &.disabled {
                    background-color: $main-color;
                    color: $black-color;
                }
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border: 1px solid $main-color;
                }
            }
        }
        .owl-nav {
            margin-top: 0;
        }
    }
}
.beyond-style-shape {
    position: absolute;
    left: 0;
    bottom: 100px;
    z-index: -1;
}
.beyond-style-area-with-bg {
    // background-image: url(assets/img/more-home/beyond/beyond-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $black-color;
        z-index: -1;
        opacity: .88;
    }
    .main-section-title {
        h2 {
            color: $white-color;
        }
    }
    .beyond-style-list-tab {
        #beyond-style-item {
            .beyond-style-content {
                padding-left: 20px;

                h3 {
                    color: $white-color;
                }
                .bold {
                    color: $white-color;
                }
                p {
                    color: #C4C4C4;
                }
            }
            .beyond-style-image {
                text-align: center;

                img {
                    width: auto;
                    display: inline-block;
                    border-radius: 5px;
                }
            }
        }
        .owl-theme {
            .owl-nav {
                [class*=owl-] {
                    background-color: #172632;
                    color: $main-color;
                    border: 1px solid #00C195;
                }
            }
        }
    }
}

/*----- Fun Fact Style Area CSS -----*/
.fun-fact-style-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.fun-fact-style-card {
    position: relative;
    background-color: #F5F7FB;
    padding: 35px 35px 35px 123px;
    border-radius: 5px;
    margin: {
        top: 12px;
        bottom: 12px;
    };
    transition: $transition;
    h3 {
        font-weight: 700;
        font-size: 55px;
        color: $main-color;
        margin-bottom: 0;
        position: absolute;
        top: 28px;
        left: 35px;
    }
    p {
        font-size: 18px;
        color: $black-color;
        font-weight: 500;
        margin-bottom: 0;
    }
    &.bg-E5F9F4 {
        background-color: #E5F9F4;
    }
    &:hover {
        transform: translateY(-5px);
    }
}
.fun-fact-style-content {
    padding-left: 40px;

    .sub-title {
        color: $main-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 18px;
        font-weight: bold;
    }
    .bold {
        color: $black-color;
        font-weight: 500;
        font-size: 15px;
        margin-bottom: 0;
    }
    .fun-fact-btn {
        margin-top: 20px;

        .main-default-btn {
            color: $white-color;
        }
    }
}
.fun-fact-style-shape {
    position: absolute;
    right: -30px;
    bottom: -350px;
    z-index: -1;
}
.fun-fact-style-area-with-main-color {
    background-color: $main-color;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.fun-fact-style-item {
    position: relative;
    background-color: #F5F7FB;
    padding: 35px 35px 35px 123px;
    border-radius: 5px;
    margin-bottom: 30px;
    transition: $transition;

    h3 {
        font-weight: 700;
        font-size: 55px;
        color: $main-color;
        margin-bottom: 0;
        position: absolute;
        top: 28px;
        left: 35px;
    }
    p {
        font-size: 18px;
        color: $black-color;
        font-weight: 500;
        margin-bottom: 0;
    }
    &:hover {
        transform: translateY(-5px);
    }
}
.fun-fact-style-shape-2 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    opacity: 50%;
}

/*----- Find Loan Area CSS -----*/
.find-own-loan-area {
    background: linear-gradient(180deg, #F5F7FB 0%, rgba(245, 247, 251, 0) 100%);
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.find-own-loan-list-tab {
    .nav {
        padding: 0;
        margin-bottom: 45px;
        border: none;
        border-bottom: 1px solid #DDDDDD;
        text-align: center;
        display: block;

        .nav-item {
            margin-right: 30px;
            display: inline-block;

            &:last-child {
                margin-right: 0;
            }
            .nav-link {
                transition: $transition;
                color: $black-color;
                padding: 0;
                background-color: transparent;
                border: none;
                font-weight: 500;
                font-size: 15px;
                position: relative;
                padding-bottom: 20px;

                &::before {
                    position: absolute;
                    content: "";
                    height: 1px;
                    width: 0%;
                    background-color: $main-color;
                    left: 0;
                    bottom: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: $transition;
                }
                &:hover, &.active {
                    color: $main-color;

                    &::before {
                        width: 100%;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}
.find-own-loan-card {
    margin-bottom: 30px;

    .loan-content {
        position: relative;
        margin-top: -30px;

        .tag {
            display: inline-block;
            padding: 8px 22px; 
            background-color: $main-color;
            color: $white-color;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 22px;
        }
        h3 {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 0;
        }
    }
}
.find-own-loan-slider {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            transition: $transition;

            [class*=owl-] {
                color: $black-color;
                margin: 0;
                position: absolute;
                left: -70px;
                top: 30%;
                transform: translateY(-30%);
                padding: 0;
                transition: $transition;
                background-color: $main-color;
                color: $white-color;
                display: inline-block;
                height: 50px;
                width: 50px;
                line-height: 50px;
                text-align: center;
                border-radius: 50px;
                font-size: 25px;

                &.owl-next {
                    left: auto;
                    right: -70px;
                }
                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
}
.find-own-loan-box {
    margin-bottom: 30px;
    transition: $transition;

    .loan-image {
        position: relative;

        .tag {
            display: inline-block;
            padding: 8px 22px; 
            background-color: $main-color;
            color: $white-color;
            font-size: 14px;
            font-weight: 400;
            position: absolute;
            left: 20px;
            bottom: 20px;
            border-radius: 5px;
        }
    }
    .loan-content {
        margin-top: 25px;

        h3 {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 15px;
        }
        p {
            margin-bottom: 0;
            font-size: 15.8px;
        }
    }
    &:hover {
        transform: translateY(-5px);
    }
}
.find-own-loan-item {
    background-color: #F5F7FB;
    padding: 30px;
    position: relative;
    transition: $transition;
    margin-bottom: 30px;

    .icon {
        margin-bottom: 20px;

        i {
            display: inline-block;
            height: 80px;
            width: 80px;
            line-height: 80px;
            background-color: $main-color;
            color: $white-color;
            text-align: center;
            font-size: 45px;
            border-radius: 50px;
            transition: $transition;
        }
    }
    .loan-content {
        h3 {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 15px;
            transition: $transition;
        }
        p {
            color: #767676;
            transition: $transition;
            font-size: 15px;
        }
    }
    .tag {
        position: absolute;
        right: 15px;
        top: 10px;
        color: $main-color;
        font-size: 15px;
        font-weight: 100;
    }
    &:hover {
        background-color: $black-color;

        .icon {
            i {
                background-color: $white-color;
                color: $main-color;
            }
        }
        .loan-content {
            h3 {
                color: $white-color;
            }
            p {
                color: $white-color;
            }
        }
    }
}

/*----- Overview Style Area CSS -----*/
.overview-style-area {
    position: relative;
    z-index: 1;
}
.overview-style-inner-box {
    // background-image: url(assets/img/more-home/overview-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
    padding: {
        top: 100px;
        bottom: 100px;
        left: 50px;
        right: 50px;
    };
    .content {
        text-align: center;
        max-width: 650px;
        margin: auto;

        h3 {
            font-size: 40px;
            margin-bottom: 0;
            line-height: 1.5;
            font-weight: bold;

            span {
                color: $main-color;
            }
        }
        .overview-btn {
            padding: 0;
            margin: {
                top: 30px;
                bottom: 0;
            };
            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 18px;
    
                &:last-child {
                    margin-right: 0;
                }
                .main-default-btn {
                    color: $white-color;
                }
                .main-optional-btn {
                    display: inline-block;
                    padding: 15px 30px;
                    background-color: $black-color;
                    color: $white-color;
                    border: none;
                    font-size: 15px;
                    font-weight: 400;
                    transition: $transition;
                    border-radius: 10px;
                
                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}
.overview-style-shape-1 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}

/*-----  Choice Home Area CSS -----*/
.choice-home-area {
    // background-image: url(assets/img/more-home/banner/choice-home-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    padding: {
        top: 100px;
        bottom: 100px;
    };
    position: relative;
    z-index: 1;
    .container-fluid {
        padding: {
            left: 160px;
            right: 30px;
        };
    }
    &::before {
        position: absolute;
        content: "";
        right: 0;
        top: 0;
        height: 100%;
        width: 350px;
        background-color: $black-color;
        z-index: -1;
    }
    &::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 88px;
        background-color: $black-color;
        z-index: -1;
    }
}
.choice-home-content {
    position: relative;
    top: -20px;

    .sub-title {
        display: inline-block;
        margin-bottom: 18px;
        color: $main-color;
    }
    h1 {
        font-size: 75px;
        margin-bottom: 0;
        font-weight: bold;
        color: $white-color;
    }
    .choice-home-btn {
        padding: 0;
        display: inline-flex;
        margin: {
            top: 20px;
            bottom: 0;
        };
        li {
            display: inline-block;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
            .main-default-btn {
                color: $white-color;
            }
            .video-btn {
                i {
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    display: inline-block;
                    text-align: center;
                    border-radius: 50%;
                    background: $white-color;
                    color: $black-color;
                    font-size: 30px;
                    margin-right: 10px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
                span {
                    color: $white-color;
                    font-weight: 500;
                    font-size: 14px;
                    position: relative;
                    top: -5px;
                }
            }
        }
    }
}
.mortgage-calculator-form {
    background-color: $white-color;
    padding: 35px;
    max-width: 535px;
    margin-left: auto;
    border-radius: 5px;

    h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 25px;
    }
    form {
        .form-group {
            margin-bottom: 20px;
            position: relative;

            label {
                margin-bottom: 0;
                font-size: 18px;
                position: absolute;
                top: 12px;
                left: 15px;
                color: #555555;
            }
            .form-control {
                height: 50px;
                border-radius: 4px;
                border: 1px solid #E3E9ED;
                font-weight: 400;
                font-size: 14px;
                padding-left: 40px;
                width: 100%;
                color: #555555;
                background-color: #F8FAFB;

                &:focus {
                    box-shadow: none;
                    border: 1px solid #f1f1f1;
                }
            }
            &::placeholder {
                color: #7f7f7f;
            }
            .dropdown {
                position: relative;

                a {
                    display: block;
                    height: 50px;
                    border-radius: 4px;
                    color: #555555;
                    background-color: #F8FAFB;
                    font-weight: 400;
                    font-size: 14px;
                    border: 1px solid #E3E9ED;
                    text-align: left;
                    padding-left: 40px;
                    line-height: 35px;

                    &::after {
                        border-top: 7px solid;
                        position: absolute;
                        top: 22px;
                        right: 18px;
                    }
                    &:focus {
                        box-shadow: none;
                        border: 1px solid #f1f1f1;
                    }
                }
                .dropdown-menu.show {
                    display: block;
                    width: 100%;
                }
            }
        }
        .main-default-btn {
            border: 0;
            width: 100%;
        }
    }
}
.choice-home-social-links {
    text-align: right;
    padding: 0;
    margin: {
        top: 30px;
        bottom: 0;
    };
    li {
        display: inline-block;
        margin-right: 10px;

        a {
            display: inline-block;
            height: 35px;
            width: 35px;
            line-height: 35px;
            background: #C4C4C4;
            color: $black-color ;
            font-size: 20px;
            text-align: center;
            border-radius: 50px;
            transition: $transition;

            &:hover {
                background-color: $main-color;
                transform: translateY(-5px);
                color: $white-color;
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
.choice-home-information {
    padding: 0;
    margin-bottom: 0;
    position: absolute;
    top: 48%;
    transform: translateY(-48%);
    left: -10%;
    transform: rotate(-90deg);

    li {
        list-style-type: none;
        display: inline-block;
        margin-right: 15px;
        font-size: 14px;

        &:last-child {
            margin-right: 0;
        }
        span {
            color: $white-color;
            font-weight: 600;
            font-size: 14px;
        }
        a {
            color: #DDDDDD;

            &:hover {
                color: $main-color;
            }
        }
    }
}

/*-----  Expect Style Area CSS -----*/
.expect-style-area {
    background-color: $black-color;

    .main-section-title {
        max-width: 630px;
        margin: 0 auto 45px;

        h2 {
            color: $white-color;
        }
    }
}
.expect-style-card {
    background-color: #172632;
    padding: 35px 50px;
    margin-bottom: 30px;
    border: 1px solid #172632;
    transition: $transition;

    h3 {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 18px;
        color: $white-color;
    }
    p {
        font-size: 15px;
        color: #DDDDDD;
        margin-bottom: 18px;
    }
    a {
        font-size: 15px;
        font-weight: 500;
        color: $white-color;
    }
    &:hover {
        border: 1px solid $main-color;
        transform: translateY(-5px);

        a {
            color: $main-color;
        }
    }
}

/*-----  Confidence Home Area CSS -----*/
.confidence-home-area {
    // background-image: url(assets/img/more-home/banner/confidence-home-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: {
        top: 100px;
        bottom: 100px;
    };
    .container-fluid {
        padding: {
            left: 30px;
            right: 30px;
        };
    }
}
.confidence-home-content {
    position: relative;
    top: -20px;

    .sub-title {
        display: inline-block;
        margin-bottom: 15px;
        color: $main-color;
    }
    h1 {
        font-size: 85px;
        margin-bottom: 12px;
        font-weight: bold;
    }
    p {
        margin-bottom: 0;
        font-size: 15px;
        max-width: 610px;
    }
    .confidence-home-btn {
        padding: 0;
        display: inline-flex;
        margin: {
            top: 30px;
            bottom: 0;
        };
        li {
            display: inline-block;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
            .main-default-btn {
                color: $white-color;
            }
            .video-btn {
                i {
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    display: inline-block;
                    text-align: center;
                    border-radius: 50%;
                    background: $black-color;
                    color: $white-color;
                    font-size: 30px;
                    margin-right: 10px;
                    transition: $transition;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
                span {
                    color: $black-color;
                    font-weight: 500;
                    font-size: 14px;
                    position: relative;
                    top: -5px;
                }
            }
        }
    }
}

.confidence-home-area-wrap {
    // background-image: url(assets/img/more-home/banner/confidence-home-bg-2.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0px 0px 200px 0px;
    position: relative;
    z-index: 1;
    padding: {
        top: 350px;
        bottom: 220px;
    };
    &::before {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 72.4%);
        opacity: 0.75;
        border-radius: 0px 0px 200px 0px;
        z-index: -1;
    }
}
.confidence-home-wrap-content {
    max-width: 650px;

    .sub-title {
        display: inline-block;
        margin-bottom: 12px;
        color: $main-color;
    }
    h1 {
        font-size: 85px;
        margin-bottom: 15px;
        font-weight: 400;
        color: $white-color;

        b {
            font-weight: 900;
        }
    }
    p {
        margin-bottom: 0;
        font-size: 15px;
        color: $white-color;
    }
    .confidence-home-btn {
        padding: 0;
        display: inline-flex;
        margin: {
            top: 35px;
            bottom: 0;
        };
        li {
            display: inline-block;
            margin-right: 30px;

            &:last-child {
                margin-right: 0;
            }
            .main-default-btn {
                color: $white-color;
            }
            .main-optional-btn {
                display: inline-block;
                padding: 14px 30px;
                background-color: transparent;
                color: $white-color;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;
                border: 1px solid $white-color;
            
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                    border: 1px solid $main-color;
                }
            }
        }
    }
}

/*-----  Get Quote Style Area CSS -----*/
.get-quote-style-area {
    background-color: $main-color;
    padding-top: 20px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.get-quote-style-content {
    position: relative;
    top: -15px;

    .sub-title {
        color: $white-color;
        display: inline-block;
        margin-bottom: 18px;
    }
    h3 {
        font-size: 40px;
        margin-bottom: 0;
        font-weight: bold;
        color: $white-color;
    }
    .quote-btn {
        padding: 0;
        margin: {
            top: 25px;
            bottom: 0;
        };
        li {
            list-style-type: none;
            display: inline-block;
            margin-right: 25px;

            &:last-child  {
                margin-right: 0;
            }
            .main-default-btn {
                background-color: $black-color;
                color: $white-color;

                &:hover {
                    background-color: $white-color;
                    color: $main-color;
                }
            }
            .main-optional-btn {
                display: inline-block;
                padding: 15px 30px;
                background-color: $white-color;
                color: $black-color;
                border: none;
                font-size: 15px;
                font-weight: 400;
                transition: $transition;
                border-radius: 10px;
            
                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
}
.get-quote-style-shape-1 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    opacity: 50%;
}
.get-quote-style-shape-2 {
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: -1;
}

/*----- Process Style Area CSS -----*/
.process-style-area {
    // background-image: url(assets/img/more-home/process/process-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.process-style-accordion {
    .accordion-item {
        border: none;
        transition: $transition;
        border-radius: 0;
        position: relative;
        z-index: 1;
        background-color: transparent;

        &::before {
            position: absolute;
            content: '';
            width: 1px;
            height: 100%;
            z-index: -1;
            left: 25px;
            top: 0;
            background-color: $main-color;
        }
        &:last-child {
            .accordion-button {
                padding-bottom: 0;
            }
            .accordion-body {
                padding: {
                    top: 10px;
                    bottom: 0;
                };
            }
        }
        .accordion-button {
            color: $black-color;
            box-shadow: unset;
            margin-bottom: 0;
            display: block;
            border: none;
            width: 100%;
            font-size: 18.8px;
            font-weight: 600;
            background-color: transparent;
            padding: 0 0 15px 0;
            
            span {
                display: inline-block;
                height: 50px;
                width: 50px;
                line-height: 50px;
                text-align: center;
                background-color: $main-color;
                color: $white-color;
                border-radius: 50px;
                margin-right: 10px;
                font-size: 16px;
            }
        }
        .accordion-body {
            padding: 0px 0 20px 60px;

            .bold {
                color: $black-color;
                font-weight: 500;
                font-size: 15px;
                margin-bottom: 10px;
            }
            p {
                color: $paragraph-color;
                font-size: 15px;
                margin-bottom: 0;
                font-size: 15px;
            }
        }
    }
}
.process-style-wrap-image {
    position: relative;
    z-index: 1;
    padding: {
        top: 0;
        bottom: 10px;
        right: 10px;
    };
    border-radius: 0px 0px 100px 0px;

    img {
        border-radius: 0px 0px 100px 0px;
    }
    &::before {
        position: absolute;
        content: "";
        height: 495px;
        width: 550px;
        background-color: transparent;
        right: 0;
        bottom: 0;
        z-index: -1;
        border-radius: 0px 0px 100px 0px;
        border: 1px solid $main-color;
    }
}

/*=================================
Buy Now Btn
====================================*/
.buy-now-btn {
    img {
        top: 50%;
        left: 20px;
        width: 15px;
        position: absolute;
        transform: translateY(-50%);
    }
    right: 30px;
    z-index: 99;
    top: 50%;
    position: fixed;
    transform: translateY(-50%);
    border-radius: 30px;
    display: inline-block;
    color: $white-color !important;
    background-color: #82b440;
    padding: 10px 20px 10px 42px;
    font-size: 15px;
    font-weight: 600;
    animation: {
        name: tada;
        duration: 5s;
        fill-mode: both;
        iteration-count: infinite;
    };
    &:hover {
        background-color: $main-color;
        color: $white-color !important;
    }
}
@keyframes tada {
    0% {
        transform: scale3d(1, 1, 1);
    }
    10%, 20% {
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }
    30%, 50%, 70%, 90% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%, 60%, 80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}